var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reason-item" },
    [
      _c(
        "van-cell",
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-with-switch" }, [
              _c(
                "span",
                {
                  staticClass: "select-reason",
                  style: _vm.selectReasonIconStyle,
                  on: { click: _vm.selectReason },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-menu select-reason-icon",
                  }),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _c("span", { staticClass: "text" }, [_vm._v("案由")]),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "name-input",
                attrs: { id: "reason-input", placeholder: "请选择或填写案由" },
                domProps: { value: _vm.name },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.name = $event.target.value
                  },
                },
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "iconfont icon-clear-2 name-input-clear",
                staticStyle: { color: "#c8c9cc" },
                on: { click: _vm.clearName },
              }),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSuggestion,
              expression: "showSuggestion",
            },
          ],
          staticClass: "search-suggestion",
        },
        [
          _c("van-loading", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searching,
                expression: "searching",
              },
            ],
          }),
          _vm._l(_vm.suggestionList, function (p) {
            return _c(
              "div",
              {
                key: p.caseReasonId,
                staticClass: "suggestion-list-item",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.select(p)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(p.name))]),
                _c("div", { staticClass: "attr" }, [_vm._v("民事")]),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.picking,
              expression: "picking",
            },
          ],
          staticClass: "reason-picker",
        },
        [
          _c("van-tree-select", {
            staticStyle: { height: "100%" },
            attrs: {
              items: _vm.reasons,
              "main-active-index": _vm.mainActiveIndex,
              "active-id": _vm.activeId,
            },
            on: { "click-nav": _vm.onNavClick },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _vm.reasons &&
                    _vm.reasons.length > 0 &&
                    _vm.reasons[0] &&
                    _vm.reasons[0].children
                      ? _vm._l(_vm.reasons[0].children, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "select__item" },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onItemClick(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.text))]
                              ),
                              _c("i", {
                                staticClass: "van-icon van-icon-arrow",
                                on: {
                                  click: function ($event) {
                                    return _vm.onItemArrowClick(item)
                                  },
                                },
                              }),
                            ]
                          )
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "no-reason-tip",
          attrs: { position: "top", overlay: false },
          model: {
            value: _vm.showReasonTip,
            callback: function ($$v) {
              _vm.showReasonTip = $$v
            },
            expression: "showReasonTip",
          },
        },
        [
          _c("van-icon", { attrs: { name: "fail" } }),
          _vm._v(" 没有上一级案由了 "),
        ],
        1
      ),
      _vm.showConfirmBtn
        ? _c(
            "div",
            { staticClass: "confirm-btn" },
            [
              _c(
                "van-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.confirm(true)
                    },
                  },
                },
                [_vm._v("保存并更新案件信息")]
              ),
              _c(
                "van-button",
                {
                  attrs: { plain: "", hairline: "", type: "info" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.confirm(false)
                    },
                  },
                },
                [_vm._v("直接保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }