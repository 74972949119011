// eslint-disable-next-line no-undef
export default [
  {
    label: '民事',
    value: '10123',
    caseType: 'LITIGATION',
    children: [
      {
        label: '人格权纠纷',
        value: '10124',
        caseType: 'LITIGATION',
        children: [
          {
            label: '人格权纠纷',
            value: '13281',
            caseType: 'LITIGATION',
            children: [
              {
                label: '生命权、身体权、健康权纠纷',
                value: '10125',
                caseType: 'LITIGATION',
              },
              {
                label: '姓名权纠纷',
                value: '10126',
                caseType: 'LITIGATION',
              },
              {
                label: '肖像权纠纷',
                value: '10127',
                caseType: 'LITIGATION',
              },
              {
                label: '名誉权纠纷',
                value: '10128',
                caseType: 'LITIGATION',
              },
              {
                label: '荣誉权纠纷',
                value: '10129',
                caseType: 'LITIGATION',
              },
              {
                label: '隐私权、个人信息保护纠纷',
                value: '10130',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '隐私权纠纷',
                    value: '13344',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '个人信息保护纠纷',
                    value: '13345',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '婚姻自主权纠纷',
                value: '10131',
                caseType: 'LITIGATION',
              },
              {
                label: '人身自由权纠纷',
                value: '10132',
                caseType: 'LITIGATION',
              },
              {
                label: '一般人格权纠纷',
                value: '10133',
                caseType: 'LITIGATION',
              },
              {
                label: '名称权纠纷',
                value: '13286',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '婚姻家庭、继承纠纷',
        value: '10134',
        caseType: 'LITIGATION',
        children: [
          {
            label: '婚姻家庭纠纷',
            value: '10135',
            caseType: 'LITIGATION',
            children: [
              {
                label: '婚约财产纠纷',
                value: '10136',
                caseType: 'LITIGATION',
              },
              {
                label: '离婚纠纷',
                value: '10137',
                caseType: 'LITIGATION',
              },
              {
                label: '离婚后财产纠纷',
                value: '10138',
                caseType: 'LITIGATION',
              },
              {
                label: '离婚后损害责任纠纷',
                value: '10139',
                caseType: 'LITIGATION',
              },
              {
                label: '婚姻无效纠纷',
                value: '10140',
                caseType: 'LITIGATION',
              },
              {
                label: '撤销婚姻纠纷',
                value: '10141',
                caseType: 'LITIGATION',
              },
              {
                label: '夫妻财产约定纠纷',
                value: '10142',
                caseType: 'LITIGATION',
              },
              {
                label: '同居关系纠纷',
                value: '10143',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '同居关系析产纠纷',
                    value: '10144',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '同居关系子女抚养纠纷',
                    value: '10145',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '抚养纠纷',
                value: '10146',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '抚养费纠纷',
                    value: '10147',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '变更抚养关系纠纷',
                    value: '10148',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '扶养纠纷',
                value: '10149',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '扶养费纠纷',
                    value: '10150',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '变更扶养关系纠纷',
                    value: '10151',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '赡养纠纷',
                value: '10152',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '赡养费纠纷',
                    value: '10153',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '变更赡养关系纠纷',
                    value: '10154',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '收养关系纠纷',
                value: '10155',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '确认收养关系纠纷',
                    value: '10156',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '解除收养关系纠纷',
                    value: '10157',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '监护权纠纷',
                value: '10158',
                caseType: 'LITIGATION',
              },
              {
                label: '探望权纠纷',
                value: '10159',
                caseType: 'LITIGATION',
              },
              {
                label: '分家析产纠纷',
                value: '10160',
                caseType: 'LITIGATION',
              },
              {
                label: '婚内夫妻财产分割纠纷',
                value: '13288',
                caseType: 'LITIGATION',
              },
              {
                label: '亲子关系纠纷',
                value: '13289',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '确认亲子关系纠纷',
                    value: '13346',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '否认亲子关系纠纷',
                    value: '13347',
                    caseType: 'LITIGATION',
                  },
                ],
              },
            ],
          },
          {
            label: '继承纠纷',
            value: '10161',
            caseType: 'LITIGATION',
            children: [
              {
                label: '法定继承纠纷',
                value: '10162',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '转继承纠纷',
                    value: '10163',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '代位继承纠纷',
                    value: '10164',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '遗嘱继承纠纷',
                value: '10165',
                caseType: 'LITIGATION',
              },
              {
                label: '被继承人债务清偿纠纷',
                value: '10166',
                caseType: 'LITIGATION',
              },
              {
                label: '遗赠纠纷',
                value: '10167',
                caseType: 'LITIGATION',
              },
              {
                label: '遗赠扶养协议纠纷',
                value: '10168',
                caseType: 'LITIGATION',
              },
              {
                label: '遗产管理纠纷',
                value: '13290',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '物权纠纷',
        value: '10169',
        caseType: 'LITIGATION',
        children: [
          {
            label: '不动产登记纠纷',
            value: '10170',
            caseType: 'LITIGATION',
            children: [
              {
                label: '异议登记不当损害责任纠纷',
                value: '10171',
                caseType: 'LITIGATION',
              },
              {
                label: '虚假登记损害责任纠纷',
                value: '10172',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '物权保护纠纷',
            value: '10173',
            caseType: 'LITIGATION',
            children: [
              {
                label: '物权确认纠纷',
                value: '10174',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '所有权确认纠纷',
                    value: '10175',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '用益物权确认纠纷',
                    value: '10176',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '担保物权确认纠纷',
                    value: '10177',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '返还原物纠纷',
                value: '10178',
                caseType: 'LITIGATION',
              },
              {
                label: '排除妨害纠纷',
                value: '10179',
                caseType: 'LITIGATION',
              },
              {
                label: '消除危险纠纷',
                value: '10180',
                caseType: 'LITIGATION',
              },
              {
                label: '修理重作更换纠纷',
                value: '10181',
                caseType: 'LITIGATION',
              },
              {
                label: '恢复原状纠纷',
                value: '10182',
                caseType: 'LITIGATION',
              },
              {
                label: '财产损害赔偿纠纷',
                value: '10183',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '所有权纠纷',
            value: '10184',
            caseType: 'LITIGATION',
            children: [
              {
                label: '侵害集体经济组织成员权益纠纷',
                value: '10185',
                caseType: 'LITIGATION',
              },
              {
                label: '建筑物区分所有权纠纷',
                value: '10186',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '业主专有权纠纷',
                    value: '10187',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '业主共有权纠纷',
                    value: '10188',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '车位纠纷',
                    value: '10189',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '车库纠纷',
                    value: '10190',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '业主撤销权纠纷',
                value: '10191',
                caseType: 'LITIGATION',
              },
              {
                label: '业主知情权纠纷',
                value: '10192',
                caseType: 'LITIGATION',
              },
              {
                label: '遗失物返还纠纷',
                value: '10193',
                caseType: 'LITIGATION',
              },
              {
                label: '漂流物返还纠纷',
                value: '10194',
                caseType: 'LITIGATION',
              },
              {
                label: '埋藏物返还纠纷',
                value: '10195',
                caseType: 'LITIGATION',
              },
              {
                label: '隐藏物返还纠纷',
                value: '10196',
                caseType: 'LITIGATION',
              },
              {
                label: '相邻关系纠纷',
                value: '10197',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '相邻用水排水纠纷',
                    value: '10198',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻通行纠纷',
                    value: '10199',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻土地建筑物利用关系纠纷',
                    value: '10200',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻通风纠纷',
                    value: '10201',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻采光日照纠纷',
                    value: '10202',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻污染侵害纠纷',
                    value: '10203',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '相邻损害防免关系纠纷',
                    value: '10204',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '共有纠纷',
                value: '10205',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '共有权确认纠纷',
                    value: '10206',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '共有物分割纠纷',
                    value: '10207',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '共有人优先购买权纠纷',
                    value: '10208',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '债权人代位析产纠纷',
                    value: '13348',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '添附物归属纠纷',
                value: '13291',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '用益物权纠纷',
            value: '10209',
            caseType: 'LITIGATION',
            children: [
              {
                label: '海域使用权纠纷',
                value: '10210',
                caseType: 'LITIGATION',
              },
              {
                label: '探矿权纠纷',
                value: '10211',
                caseType: 'LITIGATION',
              },
              {
                label: '采矿权纠纷',
                value: '10212',
                caseType: 'LITIGATION',
              },
              {
                label: '取水权纠纷',
                value: '10213',
                caseType: 'LITIGATION',
              },
              {
                label: '养殖权纠纷',
                value: '10214',
                caseType: 'LITIGATION',
              },
              {
                label: '捕捞权纠纷',
                value: '10215',
                caseType: 'LITIGATION',
              },
              {
                label: '土地承包经营权纠纷',
                value: '10216',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '土地承包经营权确认纠纷',
                    value: '10217',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '承包地征收补偿费用分配纠纷',
                    value: '10218',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地承包经营权继承纠纷',
                    value: '10219',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '建设用地使用权纠纷',
                value: '10220',
                caseType: 'LITIGATION',
              },
              {
                label: '宅基地使用权纠纷',
                value: '10221',
                caseType: 'LITIGATION',
              },
              {
                label: '地役权纠纷',
                value: '10222',
                caseType: 'LITIGATION',
              },
              {
                label: '土地经营权纠纷',
                value: '13292',
                caseType: 'LITIGATION',
              },
              {
                label: '居住权纠纷',
                value: '13293',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '担保物权纠纷',
            value: '10223',
            caseType: 'LITIGATION',
            children: [
              {
                label: '抵押权纠纷',
                value: '10224',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '建筑物和其他土地附着物抵押权纠纷',
                    value: '10225',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '在建建筑物抵押权纠纷',
                    value: '10226',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设用地使用权抵押权纠纷',
                    value: '10227',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地经营权抵押权纠纷',
                    value: '10228',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '动产抵押权纠纷',
                    value: '10229',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '在建船舶航空器抵押权纠纷',
                    value: '10230',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '动产浮动抵押权纠纷',
                    value: '10231',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '最高额抵押权纠纷',
                    value: '10232',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '探矿权抵押权纠纷',
                    value: '13349',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '采矿权抵押权纠纷',
                    value: '13350',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '海域使用权抵押权纠纷',
                    value: '13351',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '质权纠纷',
                value: '10233',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '动产质权纠纷',
                    value: '10234',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '转质权纠纷',
                    value: '10235',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '最高额质权纠纷',
                    value: '10236',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '票据质权纠纷',
                    value: '10237',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '债券质权纠纷',
                    value: '10238',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '存单质权纠纷',
                    value: '10239',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '仓单质权纠纷',
                    value: '10240',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '提单质权纠纷',
                    value: '10241',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '股权质权纠纷',
                    value: '10242',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '基金份额质权纠纷',
                    value: '10243',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '知识产权质权纠纷',
                    value: '10244',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '应收账款质权纠纷',
                    value: '10245',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '留置权纠纷',
                value: '10246',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '占有保护纠纷',
            value: '10247',
            caseType: 'LITIGATION',
            children: [
              {
                label: '占有物返还纠纷',
                value: '10248',
                caseType: 'LITIGATION',
              },
              {
                label: '占有排除妨害纠纷',
                value: '10249',
                caseType: 'LITIGATION',
              },
              {
                label: '占有消除危险纠纷',
                value: '10250',
                caseType: 'LITIGATION',
              },
              {
                label: '占有物损害赔偿纠纷',
                value: '10251',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '合同、准合同纠纷',
        value: '10252',
        caseType: 'LITIGATION',
        children: [
          {
            label: '合同纠纷',
            value: '10253',
            caseType: 'LITIGATION',
            children: [
              {
                label: '缔约过失责任纠纷',
                value: '10254',
                caseType: 'LITIGATION',
              },
              {
                label: '确认合同效力纠纷',
                value: '10255',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '确认合同有效纠纷',
                    value: '10256',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '确认合同无效纠纷',
                    value: '10257',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '债权人代位权纠纷',
                value: '10258',
                caseType: 'LITIGATION',
              },
              {
                label: '债权人撤销权纠纷',
                value: '10259',
                caseType: 'LITIGATION',
              },
              {
                label: '债权转让合同纠纷',
                value: '10260',
                caseType: 'LITIGATION',
              },
              {
                label: '债务转移合同纠纷',
                value: '10261',
                caseType: 'LITIGATION',
              },
              {
                label: '债权债务概括转移合同纠纷',
                value: '10262',
                caseType: 'LITIGATION',
              },
              {
                label: '悬赏广告纠纷',
                value: '10263',
                caseType: 'LITIGATION',
              },
              {
                label: '买卖合同纠纷',
                value: '10264',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '分期付款买卖合同纠纷',
                    value: '10265',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '凭样品买卖合同纠纷',
                    value: '10266',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '试用买卖合同纠纷',
                    value: '10267',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '互易纠纷',
                    value: '10268',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '国际货物买卖合同纠纷',
                    value: '10269',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '信息网络买卖合同纠纷',
                    value: '10270',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '招标投标买卖合同纠纷',
                    value: '10272',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '所有权保留买卖合同纠纷',
                    value: '13352',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '拍卖合同纠纷',
                value: '10273',
                caseType: 'LITIGATION',
              },
              {
                label: '建设用地使用权合同纠纷',
                value: '10274',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '建设用地使用权出让合同纠纷',
                    value: '10275',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设用地使用权转让合同纠纷',
                    value: '10276',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '临时用地合同纠纷',
                value: '10277',
                caseType: 'LITIGATION',
              },
              {
                label: '探矿权转让合同纠纷',
                value: '10278',
                caseType: 'LITIGATION',
              },
              {
                label: '采矿权转让合同纠纷',
                value: '10279',
                caseType: 'LITIGATION',
              },
              {
                label: '房地产开发经营合同纠纷',
                value: '10280',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '委托代建合同纠纷',
                    value: '10281',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '合资合作开发房地产合同纠纷',
                    value: '10282',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '项目转让合同纠纷',
                    value: '10283',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '房屋买卖合同纠纷',
                value: '10284',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '商品房预约合同纠纷',
                    value: '10285',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '商品房预售合同纠纷',
                    value: '10286',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '商品房销售合同纠纷',
                    value: '10287',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '商品房委托代理销售合同纠纷',
                    value: '10288',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '经济适用房转让合同纠纷',
                    value: '10289',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '农村房屋买卖合同纠纷',
                    value: '10290',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '民事主体间房屋拆迁补偿合同纠纷',
                value: '10291',
                caseType: 'LITIGATION',
              },
              {
                label: '供用电合同纠纷',
                value: '10292',
                caseType: 'LITIGATION',
              },
              {
                label: '供用水合同纠纷',
                value: '10293',
                caseType: 'LITIGATION',
              },
              {
                label: '供用气合同纠纷',
                value: '10294',
                caseType: 'LITIGATION',
              },
              {
                label: '供用热力合同纠纷',
                value: '10295',
                caseType: 'LITIGATION',
              },
              {
                label: '赠与合同纠纷',
                value: '10296',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '公益事业捐赠合同纠纷',
                    value: '10297',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '附义务赠与合同纠纷',
                    value: '10298',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '借款合同纠纷',
                value: '10299',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '金融借款合同纠纷',
                    value: '10300',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '同业拆借纠纷',
                    value: '10301',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '民间借贷纠纷',
                    value: '10303',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '小额借款合同纠纷',
                    value: '10304',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '金融不良债权转让合同纠纷',
                    value: '10305',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '金融不良债权追偿纠纷',
                    value: '10306',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '保证合同纠纷',
                value: '10307',
                caseType: 'LITIGATION',
              },
              {
                label: '抵押合同纠纷',
                value: '10308',
                caseType: 'LITIGATION',
              },
              {
                label: '质押合同纠纷',
                value: '10309',
                caseType: 'LITIGATION',
              },
              {
                label: '定金合同纠纷',
                value: '10310',
                caseType: 'LITIGATION',
              },
              {
                label: '进出口押汇纠纷',
                value: '10311',
                caseType: 'LITIGATION',
              },
              {
                label: '储蓄存款合同纠纷',
                value: '10312',
                caseType: 'LITIGATION',
              },
              {
                label: '银行卡纠纷',
                value: '10313',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '借记卡纠纷',
                    value: '10314',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '信用卡纠纷',
                    value: '10315',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '租赁合同纠纷',
                value: '10316',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '土地租赁合同纠纷',
                    value: '10317',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '房屋租赁合同纠纷',
                    value: '10318',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '车辆租赁合同纠纷',
                    value: '10319',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建筑设备租赁合同纠纷',
                    value: '10320',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '融资租赁合同纠纷',
                value: '10321',
                caseType: 'LITIGATION',
              },
              {
                label: '承揽合同纠纷',
                value: '10322',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '加工合同纠纷',
                    value: '10323',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '定作合同纠纷',
                    value: '10324',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '修理合同纠纷',
                    value: '10325',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '复制合同纠纷',
                    value: '10326',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '测试合同纠纷',
                    value: '10327',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '检验合同纠纷',
                    value: '10328',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路机车车辆建造合同纠纷',
                    value: '10329',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '建设工程合同纠纷',
                value: '10330',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '建设工程勘察合同纠纷',
                    value: '10331',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设工程设计合同纠纷',
                    value: '10332',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设工程施工合同纠纷',
                    value: '10333',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设工程价款优先受偿权纠纷',
                    value: '10334',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设工程分包合同纠纷',
                    value: '10335',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建设工程监理合同纠纷',
                    value: '10336',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '装饰装修合同纠纷',
                    value: '10337',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路修建合同纠纷',
                    value: '10338',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '农村建房施工合同纠纷',
                    value: '10339',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '运输合同纠纷',
                value: '10340',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '公路旅客运输合同纠纷',
                    value: '10341',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公路货物运输合同纠纷',
                    value: '10342',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '水路旅客运输合同纠纷',
                    value: '10343',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '水路货物运输合同纠纷',
                    value: '10344',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '航空旅客运输合同纠纷',
                    value: '10345',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '航空货物运输合同纠纷',
                    value: '10346',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '出租汽车运输合同纠纷',
                    value: '10347',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '管道运输合同纠纷',
                    value: '10348',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '城市公交运输合同纠纷',
                    value: '10349',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '联合运输合同纠纷',
                    value: '10350',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '多式联运合同纠纷',
                    value: '10351',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路货物运输合同纠纷',
                    value: '10352',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路旅客运输合同纠纷',
                    value: '10353',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路行李运输合同纠纷',
                    value: '10354',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路包裹运输合同纠纷',
                    value: '10355',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '国际铁路联运合同纠纷',
                    value: '10356',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '保管合同纠纷',
                value: '10357',
                caseType: 'LITIGATION',
              },
              {
                label: '仓储合同纠纷',
                value: '10358',
                caseType: 'LITIGATION',
              },
              {
                label: '委托合同纠纷',
                value: '10359',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '进出口代理合同纠纷',
                    value: '10360',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '货运代理合同纠纷',
                    value: '10361',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '民用航空运输销售代理合同纠纷',
                    value: '10362',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '诉讼仲裁人民调解代理合同纠纷',
                    value: '10363',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '销售代理合同纠纷',
                    value: '13353',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '委托理财合同纠纷',
                value: '10364',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '金融委托理财合同纠纷',
                    value: '10365',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '民间委托理财合同纠纷',
                    value: '10366',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '行纪合同纠纷',
                value: '10367',
                caseType: 'LITIGATION',
              },
              {
                label: '中介合同纠纷',
                value: '10368',
                caseType: 'LITIGATION',
              },
              {
                label: '补偿贸易纠纷',
                value: '10369',
                caseType: 'LITIGATION',
              },
              {
                label: '借用合同纠纷',
                value: '10370',
                caseType: 'LITIGATION',
              },
              {
                label: '典当纠纷',
                value: '10371',
                caseType: 'LITIGATION',
              },
              {
                label: '合伙合同纠纷',
                value: '10372',
                caseType: 'LITIGATION',
              },
              {
                label: '种植养殖回收合同纠纷',
                value: '10373',
                caseType: 'LITIGATION',
              },
              {
                label: '彩票奖券纠纷',
                value: '10374',
                caseType: 'LITIGATION',
              },
              {
                label: '中外合作勘探开发自然资源合同纠纷',
                value: '10375',
                caseType: 'LITIGATION',
              },
              {
                label: '农业承包合同纠纷',
                value: '10376',
                caseType: 'LITIGATION',
              },
              {
                label: '林业承包合同纠纷',
                value: '10377',
                caseType: 'LITIGATION',
              },
              {
                label: '渔业承包合同纠纷',
                value: '10378',
                caseType: 'LITIGATION',
              },
              {
                label: '牧业承包合同纠纷',
                value: '10379',
                caseType: 'LITIGATION',
              },
              {
                label: '土地承包经营权合同纠纷',
                value: '10380',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '土地承包经营权转让合同纠纷',
                    value: '10382',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地承包经营权互换合同纠纷',
                    value: '10383',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地经营权入股合同纠纷',
                    value: '10384',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地经营权抵押合同纠纷',
                    value: '10385',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土地经营权出租合同纠纷',
                    value: '10386',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '服务合同纠纷',
                value: '10387',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '电信服务合同纠纷',
                    value: '10388',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '邮政服务合同纠纷',
                    value: '10389',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '医疗服务合同纠纷',
                    value: '10390',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '法律服务合同纠纷',
                    value: '10391',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '旅游合同纠纷',
                    value: '10392',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '房地产咨询合同纠纷',
                    value: '10393',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '房地产价格评估合同纠纷',
                    value: '10394',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '旅店服务合同纠纷',
                    value: '10395',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '财会服务合同纠纷',
                    value: '10396',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '餐饮服务合同纠纷',
                    value: '10397',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '娱乐服务合同纠纷',
                    value: '10398',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '有线电视服务合同纠纷',
                    value: '10399',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '网络服务合同纠纷',
                    value: '10400',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '教育培训合同纠纷',
                    value: '10401',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '家政服务合同纠纷',
                    value: '10403',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '庆典服务合同纠纷',
                    value: '10404',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '殡葬服务合同纠纷',
                    value: '10405',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '农业技术服务合同纠纷',
                    value: '10406',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '农机作业服务合同纠纷',
                    value: '10407',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '保安服务合同纠纷',
                    value: '10408',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '银行结算合同纠纷',
                    value: '10409',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '快递服务合同纠纷',
                    value: '13354',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '物业服务合同纠纷',
                value: '10402',
                caseType: 'LITIGATION',
              },
              {
                label: '演出合同纠纷',
                value: '10410',
                caseType: 'LITIGATION',
              },
              {
                label: '劳务合同纠纷',
                value: '10411',
                caseType: 'LITIGATION',
              },
              {
                label: '离退休人员返聘合同纠纷',
                value: '10412',
                caseType: 'LITIGATION',
              },
              {
                label: '广告合同纠纷',
                value: '10413',
                caseType: 'LITIGATION',
              },
              {
                label: '展览合同纠纷',
                value: '10414',
                caseType: 'LITIGATION',
              },
              {
                label: '追偿权纠纷',
                value: '10415',
                caseType: 'LITIGATION',
              },
              {
                label: '预约合同纠纷',
                value: '13294',
                caseType: 'LITIGATION',
              },
              {
                label: '债务加入纠纷',
                value: '13295',
                caseType: 'LITIGATION',
              },
              {
                label: '排污权交易纠纷',
                value: '13296',
                caseType: 'LITIGATION',
              },
              {
                label: '用能权交易纠纷',
                value: '13297',
                caseType: 'LITIGATION',
              },
              {
                label: '用水权交易纠纷',
                value: '13298',
                caseType: 'LITIGATION',
              },
              {
                label: '碳排放权交易纠纷',
                value: '13299',
                caseType: 'LITIGATION',
              },
              {
                label: '碳汇交易纠纷',
                value: '13300',
                caseType: 'LITIGATION',
              },
              {
                label: '保理合同纠纷',
                value: '13301',
                caseType: 'LITIGATION',
              },
              {
                label: '居住权合同纠纷',
                value: '13302',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '不当得利纠纷',
            value: '10417',
            caseType: 'LITIGATION',
            children: [
              {
                label: '不当得利纠纷',
                value: '10418',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '无因管理纠纷',
            value: '10419',
            caseType: 'LITIGATION',
            children: [
              {
                label: '无因管理纠纷',
                value: '10420',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '知识产权与竞争纠纷',
        value: '10421',
        caseType: 'LITIGATION',
        children: [
          {
            label: '知识产权合同纠纷',
            value: '10422',
            caseType: 'LITIGATION',
            children: [
              {
                label: '著作权合同纠纷',
                value: '10423',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '委托创作合同纠纷',
                    value: '10424',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '合作创作合同纠纷',
                    value: '10425',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '著作权转让合同纠纷',
                    value: '10426',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '著作权许可使用合同纠纷',
                    value: '10427',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '出版合同纠纷',
                    value: '10428',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '表演合同纠纷',
                    value: '10429',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '音像制品制作合同纠纷',
                    value: '10430',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '广播电视播放合同纠纷',
                    value: '10431',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '邻接权转让合同纠纷',
                    value: '10432',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '邻接权许可使用合同纠纷',
                    value: '10433',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '计算机软件开发合同纠纷',
                    value: '10434',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '计算机软件著作权转让合同纠纷',
                    value: '10435',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '计算机软件著作权许可使用合同纠纷',
                    value: '10436',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '商标合同纠纷',
                value: '10437',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '商标权转让合同纠纷',
                    value: '10438',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '商标使用许可合同纠纷',
                    value: '10439',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '商标代理合同纠纷',
                    value: '10440',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '专利合同纠纷',
                value: '10441',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '专利申请权转让合同纠纷',
                    value: '10442',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '专利权转让合同纠纷',
                    value: '10443',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '发明专利实施许可合同纠纷',
                    value: '10444',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '实用新型专利实施许可合同纠纷',
                    value: '10445',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '外观设计专利实施许可合同纠纷',
                    value: '10446',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '专利代理合同纠纷',
                    value: '10447',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '植物新品种合同纠纷',
                value: '10448',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '植物新品种育种合同纠纷',
                    value: '10449',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '植物新品种申请权转让合同纠纷',
                    value: '10450',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '植物新品种权转让合同纠纷',
                    value: '10451',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '植物新品种实施许可合同纠纷',
                    value: '10452',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '集成电路布图设计合同纠纷',
                value: '10453',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '集成电路布图设计创作合同纠纷',
                    value: '10454',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '集成电路布图设计专有权转让合同纠纷',
                    value: '10455',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '集成电路布图设计许可使用合同纠纷',
                    value: '10456',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '商业秘密合同纠纷',
                value: '10457',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '技术秘密让与合同纠纷',
                    value: '10458',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术秘密许可使用合同纠纷',
                    value: '10459',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '经营秘密让与合同纠纷',
                    value: '10460',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '经营秘密许可使用合同纠纷',
                    value: '10461',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '技术合同纠纷',
                value: '10462',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '技术委托开发合同纠纷',
                    value: '10463',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术合作开发合同纠纷',
                    value: '10464',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术转化合同纠纷',
                    value: '10465',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术转让合同纠纷',
                    value: '10466',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术咨询合同纠纷',
                    value: '10467',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术服务合同纠纷',
                    value: '10468',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术培训合同纠纷',
                    value: '10469',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术中介合同纠纷',
                    value: '10470',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术进口合同纠纷',
                    value: '10471',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术出口合同纠纷',
                    value: '10472',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '职务技术成果完成人奖励报酬纠纷',
                    value: '10473',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术成果完成人署名权荣誉权奖励权纠纷',
                    value: '10474',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '技术许可合同纠纷',
                    value: '13355',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '特许经营合同纠纷',
                value: '10475',
                caseType: 'LITIGATION',
              },
              {
                label: '企业名称（商号）合同纠纷',
                value: '10476',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '企业名称（商号）转让合同纠纷',
                    value: '10477',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '企业名称（商号）使用合同纠纷',
                    value: '10478',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '特殊标志合同纠纷',
                value: '10479',
                caseType: 'LITIGATION',
              },
              {
                label: '网络域名合同纠纷',
                value: '10480',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '网络域名注册合同纠纷',
                    value: '10481',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '网络域名转让合同纠纷',
                    value: '10482',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '网络域名许可使用合同纠纷',
                    value: '10483',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '知识产权质押合同纠纷',
                value: '10484',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '知识产权权属侵权纠纷',
            value: '10485',
            caseType: 'LITIGATION',
            children: [
              {
                label: '著作权权属侵权纠纷',
                value: '10486',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '著作权权属纠纷',
                    value: '10487',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品发表权纠纷',
                    value: '10488',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品署名权纠纷',
                    value: '10489',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品修改权纠纷',
                    value: '10490',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害保护作品完整权纠纷',
                    value: '10491',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品复制权纠纷',
                    value: '10492',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品发行权纠纷',
                    value: '10493',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品出租权纠纷',
                    value: '10494',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品展览权纠纷',
                    value: '10495',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品表演权纠纷',
                    value: '10496',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品放映权纠纷',
                    value: '10497',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品广播权纠纷',
                    value: '10498',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品信息网络传播权纠纷',
                    value: '10499',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品摄制权纠纷',
                    value: '10500',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品改编权纠纷',
                    value: '10501',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品翻译权纠纷',
                    value: '10502',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害作品汇编权纠纷',
                    value: '10503',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害其他著作财产权纠纷',
                    value: '10504',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '出版者权权属纠纷',
                    value: '10505',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '表演者权权属纠纷',
                    value: '10506',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '录音录像制作者权权属纠纷',
                    value: '10507',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '广播组织权权属纠纷',
                    value: '10508',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害出版者权纠纷',
                    value: '10509',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害表演者权纠纷',
                    value: '10510',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害录音录像制作者权纠纷',
                    value: '10511',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害广播组织权纠纷',
                    value: '10512',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '计算机软件著作权权属纠纷',
                    value: '10513',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害计算机软件著作权纠纷',
                    value: '10514',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '商标权权属侵权纠纷',
                value: '10515',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '商标权权属纠纷',
                    value: '10516',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害商标权纠纷',
                    value: '10517',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '专利权权属、侵权纠纷',
                value: '10518',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '专利申请权权属纠纷',
                    value: '10519',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '专利权权属纠纷',
                    value: '10520',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害发明专利权纠纷',
                    value: '10521',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害实用新型专利权纠纷',
                    value: '10522',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害外观设计专利权纠纷',
                    value: '10523',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '假冒他人专利纠纷',
                    value: '10524',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '发明专利临时保护期使用费纠纷',
                    value: '10525',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '职务发明创造发明人设计人奖励报酬纠纷',
                    value: '10526',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '发明创造发明人设计人署名权纠纷',
                    value: '10527',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '标准必要专利使用费纠纷',
                    value: '13356',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '植物新品种权权属侵权纠纷',
                value: '10528',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '植物新品种申请权权属纠纷',
                    value: '10529',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '植物新品种权权属纠纷',
                    value: '10530',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害植物新品种权纠纷',
                    value: '10531',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '集成电路布图设计专有权权属侵权纠纷',
                value: '10532',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '集成电路布图设计专有权权属纠纷',
                    value: '10533',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害集成电路布图设计专有权纠纷',
                    value: '10534',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '侵害企业名称（商号）权纠纷',
                value: '10535',
                caseType: 'LITIGATION',
              },
              {
                label: '侵害特殊标志专有权纠纷',
                value: '10536',
                caseType: 'LITIGATION',
              },
              {
                label: '网络域名权属侵权纠纷',
                value: '10537',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '网络域名权属纠纷',
                    value: '10538',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害网络域名纠纷',
                    value: '10539',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '发现权纠纷',
                value: '10540',
                caseType: 'LITIGATION',
              },
              {
                label: '发明权纠纷',
                value: '10541',
                caseType: 'LITIGATION',
              },
              {
                label: '其他科技成果权纠纷',
                value: '10542',
                caseType: 'LITIGATION',
              },
              {
                label: '确认不侵害知识产权纠纷',
                value: '10543',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '确认不侵害专利权纠纷',
                    value: '10544',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '确认不侵害商标权纠纷',
                    value: '10545',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '确认不侵害著作权纠纷',
                    value: '10546',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '因申请知识产权临时措施损害责任纠纷',
                value: '10547',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '因申请诉前停止侵害专利权损害责任纠纷',
                    value: '10548',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '因申请诉前停止侵害注册商标专用权损害责任纠纷',
                    value: '10549',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '因申请诉前停止侵害著作权损害责任纠纷',
                    value: '10550',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '因申请诉前停止侵害植物新品种权损害责任纠纷',
                    value: '10551',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '因申请海关知识产权保护措施损害责任纠纷',
                    value: '10552',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '因恶意提起知识产权诉讼损害责任纠纷',
                value: '10553',
                caseType: 'LITIGATION',
              },
              {
                label: '专利权宣告无效后返还费用纠纷',
                value: '10554',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '不正当竞争纠纷',
            value: '10555',
            caseType: 'LITIGATION',
            children: [
              {
                label: '仿冒纠纷',
                value: '10556',
                caseType: 'LITIGATION',
                children: [
                  {
                    label:
                      '擅自使用与他人有一定影响的商品名称、包装、装潢等相同或者近似的标识纠纷',
                    value: '10557',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '擅自使用他人有一定影响的企业名称、社会组织名称、姓名纠纷',
                    value: '10558',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '擅自使用他人有一定影响的域名主体部分、网站名称、网页纠纷',
                    value: '13357',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '商业贿赂不正当竞争纠纷',
                value: '10561',
                caseType: 'LITIGATION',
              },
              {
                label: '虚假宣传纠纷',
                value: '10562',
                caseType: 'LITIGATION',
              },
              {
                label: '侵害商业秘密纠纷',
                value: '10563',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '侵害技术秘密纠纷',
                    value: '10564',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '侵害经营秘密纠纷',
                    value: '10565',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '低价倾销不正当竞争纠纷',
                value: '10566',
                caseType: 'LITIGATION',
              },
              {
                label: '捆绑销售不正当竞争纠纷',
                value: '10567',
                caseType: 'LITIGATION',
              },
              {
                label: '有奖销售纠纷',
                value: '10568',
                caseType: 'LITIGATION',
              },
              {
                label: '商业诋毁纠纷',
                value: '10569',
                caseType: 'LITIGATION',
              },
              {
                label: '串通投标不正当竞争纠纷',
                value: '10570',
                caseType: 'LITIGATION',
              },
              {
                label: '网络不正当竞争纠纷',
                value: '13303',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '垄断纠纷',
            value: '10571',
            caseType: 'LITIGATION',
            children: [
              {
                label: '垄断协议纠纷',
                value: '10572',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '横向垄断协议纠纷',
                    value: '10573',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '纵向垄断协议纠纷',
                    value: '10574',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '滥用市场支配地位纠纷',
                value: '10575',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '垄断定价纠纷',
                    value: '10576',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '掠夺定价纠纷',
                    value: '10577',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '拒绝交易纠纷',
                    value: '10578',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '限定交易纠纷',
                    value: '10579',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '捆绑交易纠纷',
                    value: '10580',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '差别待遇纠纷',
                    value: '10581',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '经营者集中纠纷',
                value: '10582',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '劳动争议、人事争议',
        value: '10583',
        caseType: 'LITIGATION',
        children: [
          {
            label: '劳动争议',
            value: '10584',
            caseType: 'LITIGATION',
            children: [
              {
                label: '劳动合同纠纷',
                value: '10585',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '确认劳动关系纠纷',
                    value: '10586',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '集体合同纠纷',
                    value: '10587',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '劳务派遣合同纠纷',
                    value: '10588',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '非全日制用工纠纷',
                    value: '10589',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '追索劳动报酬纠纷',
                    value: '10590',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '经济补偿金纠纷',
                    value: '10591',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '竞业限制纠纷',
                    value: '10592',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '社会保险纠纷',
                value: '10593',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '养老保险待遇纠纷',
                    value: '10594',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '工伤保险待遇纠纷',
                    value: '10595',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '医疗保险待遇纠纷',
                    value: '10596',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '生育保险待遇纠纷',
                    value: '10597',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '失业保险待遇纠纷',
                    value: '10598',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '福利待遇纠纷',
                value: '10599',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '人事争议',
            value: '10600',
            caseType: 'LITIGATION',
            children: [
              {
                label: '辞职纠纷',
                value: '10602',
                caseType: 'LITIGATION',
              },
              {
                label: '辞退纠纷',
                value: '10603',
                caseType: 'LITIGATION',
              },
              {
                label: '聘用合同纠纷',
                value: '10604',
                caseType: 'LITIGATION',
              },
              {
                label: '聘任合同纠纷',
                value: '13304',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '海事海商纠纷',
        value: '10605',
        caseType: 'LITIGATION',
        children: [
          {
            label: '海事海商纠纷',
            value: '10606',
            caseType: 'LITIGATION',
            children: [
              {
                label: '船舶碰撞损害责任纠纷',
                value: '10607',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶触碰损害责任纠纷',
                value: '10608',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶损坏空中设施水下设施损害责任纠纷',
                value: '10609',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶污染损害责任纠纷',
                value: '10610',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域污染损害责任纠纷',
                value: '10611',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域养殖损害责任纠纷',
                value: '10612',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域财产损害责任纠纷',
                value: '10613',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域人身损害责任纠纷',
                value: '10614',
                caseType: 'LITIGATION',
              },
              {
                label: '非法留置船舶船载货物船用燃油船用物料损害责任纠纷',
                value: '10615',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域货物运输合同纠纷',
                value: '10616',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域旅客运输合同纠纷',
                value: '10617',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域行李运输合同纠纷',
                value: '10618',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶经营管理合同纠纷',
                value: '10619',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶买卖合同纠纷',
                value: '10620',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶建造合同纠纷',
                value: '10621',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶修理合同纠纷',
                value: '10622',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶改建合同纠纷',
                value: '10623',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶拆解合同纠纷',
                value: '10624',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶抵押合同纠纷',
                value: '10625',
                caseType: 'LITIGATION',
              },
              {
                label: '航次租船合同纠纷',
                value: '10626',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶租用合同纠纷',
                value: '10627',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '定期租船合同纠纷',
                    value: '10628',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '光船租赁合同纠纷',
                    value: '10629',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '船舶融资租赁合同纠纷',
                value: '10630',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域运输船舶承包合同纠纷',
                value: '10631',
                caseType: 'LITIGATION',
              },
              {
                label: '渔船承包合同纠纷',
                value: '10632',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶属具租赁合同纠纷',
                value: '10633',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶属具保管合同纠纷',
                value: '10634',
                caseType: 'LITIGATION',
              },
              {
                label: '海运集装箱租赁合同纠纷',
                value: '10635',
                caseType: 'LITIGATION',
              },
              {
                label: '海运集装箱保管合同纠纷',
                value: '10636',
                caseType: 'LITIGATION',
              },
              {
                label: '港口货物保管合同纠纷',
                value: '10637',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶代理合同纠纷',
                value: '10638',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域货运代理合同纠纷',
                value: '10639',
                caseType: 'LITIGATION',
              },
              {
                label: '理货合同纠纷',
                value: '10640',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶物料和备品供应合同纠纷',
                value: '10641',
                caseType: 'LITIGATION',
              },
              {
                label: '船员劳务合同纠纷',
                value: '10642',
                caseType: 'LITIGATION',
              },
              {
                label: '海难救助合同纠纷',
                value: '10643',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域打捞合同纠纷',
                value: '10644',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域拖航合同纠纷',
                value: '10645',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域保险合同纠纷',
                value: '10646',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域保赔合同纠纷',
                value: '10647',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域运输联营合同纠纷',
                value: '10648',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶营运借款合同纠纷',
                value: '10649',
                caseType: 'LITIGATION',
              },
              {
                label: '海事担保合同纠纷',
                value: '10650',
                caseType: 'LITIGATION',
              },
              {
                label: '航道港口疏浚合同纠纷',
                value: '10651',
                caseType: 'LITIGATION',
              },
              {
                label: '船坞码头建造合同纠纷',
                value: '10652',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶检验合同纠纷',
                value: '10653',
                caseType: 'LITIGATION',
              },
              {
                label: '海事请求担保纠纷',
                value: '10654',
                caseType: 'LITIGATION',
              },
              {
                label: '海上通海水域运输重大责任事故责任纠纷',
                value: '10655',
                caseType: 'LITIGATION',
              },
              {
                label: '港口作业重大责任事故责任纠纷',
                value: '10656',
                caseType: 'LITIGATION',
              },
              {
                label: '港口作业纠纷',
                value: '10657',
                caseType: 'LITIGATION',
              },
              {
                label: '共同海损纠纷',
                value: '10658',
                caseType: 'LITIGATION',
              },
              {
                label: '海洋开发利用纠纷',
                value: '10659',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶共有纠纷',
                value: '10660',
                caseType: 'LITIGATION',
              },
              {
                label: '船舶权属纠纷',
                value: '10661',
                caseType: 'LITIGATION',
              },
              {
                label: '海运欺诈纠纷',
                value: '10662',
                caseType: 'LITIGATION',
              },
              {
                label: '海事债权确权纠纷',
                value: '10663',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '与公司、证券、保险、票据等有关的民事纠纷',
        value: '10664',
        caseType: 'LITIGATION',
        children: [
          {
            label: '与企业有关的纠纷',
            value: '10665',
            caseType: 'LITIGATION',
            children: [
              {
                label: '企业出资人权益确认纠纷',
                value: '10666',
                caseType: 'LITIGATION',
              },
              {
                label: '侵害企业出资人权益纠纷',
                value: '10667',
                caseType: 'LITIGATION',
              },
              {
                label: '企业公司制改造合同纠纷',
                value: '10668',
                caseType: 'LITIGATION',
              },
              {
                label: '企业股份合作制改造合同纠纷',
                value: '10669',
                caseType: 'LITIGATION',
              },
              {
                label: '企业债权转股权合同纠纷',
                value: '10670',
                caseType: 'LITIGATION',
              },
              {
                label: '企业分立合同纠纷',
                value: '10671',
                caseType: 'LITIGATION',
              },
              {
                label: '企业租赁经营合同纠纷',
                value: '10672',
                caseType: 'LITIGATION',
              },
              {
                label: '企业出售合同纠纷',
                value: '10673',
                caseType: 'LITIGATION',
              },
              {
                label: '挂靠经营合同纠纷',
                value: '10674',
                caseType: 'LITIGATION',
              },
              {
                label: '企业兼并合同纠纷',
                value: '10675',
                caseType: 'LITIGATION',
              },
              {
                label: '联营合同纠纷',
                value: '10676',
                caseType: 'LITIGATION',
              },
              {
                label: '企业承包经营合同纠纷',
                value: '10677',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '中外合资经营企业承包经营合同纠纷',
                    value: '10678',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '中外合作经营企业承包经营合同纠纷',
                    value: '10679',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '外商独资企业承包经营合同纠纷',
                    value: '10680',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '乡镇企业承包经营合同纠纷',
                    value: '10681',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '中外合资经营企业合同纠纷',
                value: '10682',
                caseType: 'LITIGATION',
              },
              {
                label: '中外合作经营企业合同纠纷',
                value: '10683',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '与公司有关的纠纷',
            value: '10684',
            caseType: 'LITIGATION',
            children: [
              {
                label: '股东资格确认纠纷',
                value: '10685',
                caseType: 'LITIGATION',
              },
              {
                label: '股东名册记载纠纷',
                value: '10686',
                caseType: 'LITIGATION',
              },
              {
                label: '请求变更公司登记纠纷',
                value: '10687',
                caseType: 'LITIGATION',
              },
              {
                label: '股东出资纠纷',
                value: '10688',
                caseType: 'LITIGATION',
              },
              {
                label: '新增资本认购纠纷',
                value: '10689',
                caseType: 'LITIGATION',
              },
              {
                label: '股东知情权纠纷',
                value: '10690',
                caseType: 'LITIGATION',
              },
              {
                label: '请求公司收购股份纠纷',
                value: '10691',
                caseType: 'LITIGATION',
              },
              {
                label: '股权转让纠纷',
                value: '10692',
                caseType: 'LITIGATION',
              },
              {
                label: '公司决议纠纷',
                value: '10693',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '公司决议效力确认纠纷',
                    value: '10694',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公司决议撤销纠纷',
                    value: '10695',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '公司设立纠纷',
                value: '10696',
                caseType: 'LITIGATION',
              },
              {
                label: '公司证照返还纠纷',
                value: '10697',
                caseType: 'LITIGATION',
              },
              {
                label: '发起人责任纠纷',
                value: '10698',
                caseType: 'LITIGATION',
              },
              {
                label: '公司盈余分配纠纷',
                value: '10699',
                caseType: 'LITIGATION',
              },
              {
                label: '损害股东利益责任纠纷',
                value: '10700',
                caseType: 'LITIGATION',
              },
              {
                label: '损害公司利益责任纠纷',
                value: '10701',
                caseType: 'LITIGATION',
              },
              {
                label: '损害公司债权人利益责任纠纷',
                value: '10702',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '股东损害公司债权人利益责任纠纷',
                    value: '13358',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '实际控制人损害公司债权人利益责任纠纷',
                    value: '13359',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '公司关联交易损害责任纠纷',
                value: '10703',
                caseType: 'LITIGATION',
              },
              {
                label: '公司合并纠纷',
                value: '10704',
                caseType: 'LITIGATION',
              },
              {
                label: '公司分立纠纷',
                value: '10705',
                caseType: 'LITIGATION',
              },
              {
                label: '公司减资纠纷',
                value: '10706',
                caseType: 'LITIGATION',
              },
              {
                label: '公司增资纠纷',
                value: '10707',
                caseType: 'LITIGATION',
              },
              {
                label: '公司解散纠纷',
                value: '10708',
                caseType: 'LITIGATION',
              },
              {
                label: '清算责任纠纷',
                value: '10710',
                caseType: 'LITIGATION',
              },
              {
                label: '上市公司收购纠纷',
                value: '10711',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '合伙企业纠纷',
            value: '10712',
            caseType: 'LITIGATION',
            children: [
              {
                label: '入伙纠纷',
                value: '10713',
                caseType: 'LITIGATION',
              },
              {
                label: '退伙纠纷',
                value: '10714',
                caseType: 'LITIGATION',
              },
              {
                label: '合伙企业财产份额转让纠纷',
                value: '10715',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '与破产有关的纠纷',
            value: '10716',
            caseType: 'LITIGATION',
            children: [
              {
                label: '请求撤销个别清偿行为纠纷',
                value: '10720',
                caseType: 'LITIGATION',
              },
              {
                label: '请求确认债务人行为无效纠纷',
                value: '10721',
                caseType: 'LITIGATION',
              },
              {
                label: '对外追收债权纠纷',
                value: '10722',
                caseType: 'LITIGATION',
              },
              {
                label: '追收未缴出资纠纷',
                value: '10723',
                caseType: 'LITIGATION',
              },
              {
                label: '追收抽逃出资纠纷',
                value: '10724',
                caseType: 'LITIGATION',
              },
              {
                label: '追收非正常收入纠纷',
                value: '10725',
                caseType: 'LITIGATION',
              },
              {
                label: '破产债权确认纠纷',
                value: '10726',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '职工破产债权确认纠纷',
                    value: '10727',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '普通破产债权确认纠纷',
                    value: '10728',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '取回权纠纷',
                value: '10729',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '一般取回权纠纷',
                    value: '10730',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '出卖人取回权纠纷',
                    value: '10731',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '破产抵销权纠纷',
                value: '10732',
                caseType: 'LITIGATION',
              },
              {
                label: '别除权纠纷',
                value: '10733',
                caseType: 'LITIGATION',
              },
              {
                label: '破产撤销权纠纷',
                value: '10734',
                caseType: 'LITIGATION',
              },
              {
                label: '损害债务人利益赔偿纠纷',
                value: '10735',
                caseType: 'LITIGATION',
              },
              {
                label: '管理人责任纠纷',
                value: '10736',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '证券纠纷',
            value: '10737',
            caseType: 'LITIGATION',
            children: [
              {
                label: '证券权利确认纠纷',
                value: '10738',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '股票权利确认纠纷',
                    value: '10739',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公司债券权利确认纠纷',
                    value: '10740',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '国债权利确认纠纷',
                    value: '10741',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '证券投资基金权利确认纠纷',
                    value: '10742',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '证券交易合同纠纷',
                value: '10743',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '股票交易纠纷',
                    value: '10744',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公司债券交易纠纷',
                    value: '10745',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '国债交易纠纷',
                    value: '10746',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '证券投资基金交易纠纷',
                    value: '10747',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '金融衍生品种交易纠纷',
                value: '10748',
                caseType: 'LITIGATION',
              },
              {
                label: '证券承销合同纠纷',
                value: '10749',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '证券代销合同纠纷',
                    value: '10750',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '证券包销合同纠纷',
                    value: '10751',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '证券投资咨询纠纷',
                value: '10752',
                caseType: 'LITIGATION',
              },
              {
                label: '证券资信评级服务合同纠纷',
                value: '10753',
                caseType: 'LITIGATION',
              },
              {
                label: '证券回购合同纠纷',
                value: '10754',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '股票回购合同纠纷',
                    value: '10755',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '国债回购合同纠纷',
                    value: '10756',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公司债券回购合同纠纷',
                    value: '10757',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '证券投资基金回购合同纠纷',
                    value: '10758',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '质押式证券回购纠纷',
                    value: '10759',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '证券上市合同纠纷',
                value: '10760',
                caseType: 'LITIGATION',
              },
              {
                label: '证券交易代理合同纠纷',
                value: '10761',
                caseType: 'LITIGATION',
              },
              {
                label: '证券上市保荐合同纠纷',
                value: '10762',
                caseType: 'LITIGATION',
              },
              {
                label: '证券发行纠纷',
                value: '10763',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '证券认购纠纷',
                    value: '10764',
                    caseType: 'LITIGATION',
                    children: [
                      {
                        label: '证券发行失败纠纷',
                        value: '10765',
                        caseType: 'LITIGATION',
                      },
                    ],
                  },
                ],
              },
              {
                label: '证券返还纠纷',
                value: '10766',
                caseType: 'LITIGATION',
              },
              {
                label: '证券欺诈责任纠纷',
                value: '10767',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '证券内幕交易责任纠纷',
                    value: '10768',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '操纵证券交易市场责任纠纷',
                    value: '10769',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '证券虚假陈述责任纠纷',
                    value: '10770',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '欺诈客户责任纠纷',
                    value: '10771',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '证券托管纠纷',
                value: '10772',
                caseType: 'LITIGATION',
              },
              {
                label: '证券登记存管结算纠纷',
                value: '10773',
                caseType: 'LITIGATION',
              },
              {
                label: '融资融券交易纠纷',
                value: '10774',
                caseType: 'LITIGATION',
              },
              {
                label: '客户交易结算资金纠纷',
                value: '10775',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '期货交易纠纷',
            value: '10776',
            caseType: 'LITIGATION',
            children: [
              {
                label: '期货经纪合同纠纷',
                value: '10777',
                caseType: 'LITIGATION',
              },
              {
                label: '期货透支交易纠纷',
                value: '10778',
                caseType: 'LITIGATION',
              },
              {
                label: '期货强行平仓纠纷',
                value: '10779',
                caseType: 'LITIGATION',
              },
              {
                label: '期货实物交割纠纷',
                value: '10780',
                caseType: 'LITIGATION',
              },
              {
                label: '期货保证合约纠纷',
                value: '10781',
                caseType: 'LITIGATION',
              },
              {
                label: '期货交易代理合同纠纷',
                value: '10782',
                caseType: 'LITIGATION',
              },
              {
                label: '侵占期货交易保证金纠纷',
                value: '10783',
                caseType: 'LITIGATION',
              },
              {
                label: '期货欺诈责任纠纷',
                value: '10784',
                caseType: 'LITIGATION',
              },
              {
                label: '操纵期货交易市场责任纠纷',
                value: '10785',
                caseType: 'LITIGATION',
              },
              {
                label: '期货内幕交易责任纠纷',
                value: '10786',
                caseType: 'LITIGATION',
              },
              {
                label: '期货虚假信息责任纠纷',
                value: '10787',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '信托纠纷',
            value: '10788',
            caseType: 'LITIGATION',
            children: [
              {
                label: '民事信托纠纷',
                value: '10789',
                caseType: 'LITIGATION',
              },
              {
                label: '营业信托纠纷',
                value: '10790',
                caseType: 'LITIGATION',
              },
              {
                label: '公益信托纠纷',
                value: '10791',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '保险纠纷',
            value: '10792',
            caseType: 'LITIGATION',
            children: [
              {
                label: '财产保险合同纠纷',
                value: '10793',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '财产损失保险合同纠纷',
                    value: '10794',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '责任保险合同纠纷',
                    value: '10795',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '信用保险合同纠纷',
                    value: '10796',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '保证保险合同纠纷',
                    value: '10797',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '保险人代位求偿权纠纷',
                    value: '10798',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '人身保险合同纠纷',
                value: '10799',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '人寿保险合同纠纷',
                    value: '10800',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '意外伤害保险合同纠纷',
                    value: '10801',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '健康保险合同纠纷',
                    value: '10802',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '再保险合同纠纷',
                value: '10803',
                caseType: 'LITIGATION',
              },
              {
                label: '保险经纪合同纠纷',
                value: '10804',
                caseType: 'LITIGATION',
              },
              {
                label: '保险代理合同纠纷',
                value: '10805',
                caseType: 'LITIGATION',
              },
              {
                label: '进出口信用保险合同纠纷',
                value: '10806',
                caseType: 'LITIGATION',
              },
              {
                label: '保险费纠纷',
                value: '10807',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '票据纠纷',
            value: '10808',
            caseType: 'LITIGATION',
            children: [
              {
                label: '票据付款请求权纠纷',
                value: '10809',
                caseType: 'LITIGATION',
              },
              {
                label: '票据追索权纠纷',
                value: '10810',
                caseType: 'LITIGATION',
              },
              {
                label: '票据交付请求权纠纷',
                value: '10811',
                caseType: 'LITIGATION',
              },
              {
                label: '票据返还请求权纠纷',
                value: '10812',
                caseType: 'LITIGATION',
              },
              {
                label: '票据损害责任纠纷',
                value: '10813',
                caseType: 'LITIGATION',
              },
              {
                label: '票据利益返还请求权纠纷',
                value: '10814',
                caseType: 'LITIGATION',
              },
              {
                label: '汇票回单签发请求权纠纷',
                value: '10815',
                caseType: 'LITIGATION',
              },
              {
                label: '票据保证纠纷',
                value: '10816',
                caseType: 'LITIGATION',
              },
              {
                label: '确认票据无效纠纷',
                value: '10817',
                caseType: 'LITIGATION',
              },
              {
                label: '票据代理纠纷',
                value: '10818',
                caseType: 'LITIGATION',
              },
              {
                label: '票据回购纠纷',
                value: '10819',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '信用证纠纷',
            value: '10820',
            caseType: 'LITIGATION',
            children: [
              {
                label: '委托开立信用证纠纷',
                value: '10821',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证开证纠纷',
                value: '10822',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证议付纠纷',
                value: '10823',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证欺诈纠纷',
                value: '10824',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证融资纠纷',
                value: '10825',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证转让纠纷',
                value: '10826',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '侵权责任纠纷',
        value: '10827',
        caseType: 'LITIGATION',
        children: [
          {
            label: '侵权责任纠纷',
            value: '10828',
            caseType: 'LITIGATION',
            children: [
              {
                label: '监护人责任纠纷',
                value: '10829',
                caseType: 'LITIGATION',
              },
              {
                label: '用人单位责任纠纷',
                value: '10830',
                caseType: 'LITIGATION',
              },
              {
                label: '劳务派遣工作人员侵权责任纠纷',
                value: '10831',
                caseType: 'LITIGATION',
              },
              {
                label: '提供劳务者致害责任纠纷',
                value: '10832',
                caseType: 'LITIGATION',
              },
              {
                label: '提供劳务者受害责任纠纷',
                value: '10833',
                caseType: 'LITIGATION',
              },
              {
                label: '网络侵权责任纠纷',
                value: '10834',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '网络侵害虚拟财产纠纷',
                    value: '13360',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '违反安全保障义务责任纠纷',
                value: '10835',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '经营场所、公共场所的经营者、管理者责任纠纷',
                    value: '10836',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '群众性活动组织者责任纠纷',
                    value: '10837',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '教育机构责任纠纷',
                value: '10838',
                caseType: 'LITIGATION',
              },
              {
                label: '产品责任纠纷',
                value: '10839',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '产品生产者责任纠纷',
                    value: '10840',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '产品销售者责任纠纷',
                    value: '10841',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '产品运输者责任纠纷',
                    value: '10842',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '产品仓储者责任纠纷',
                    value: '10843',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '机动车交通事故责任纠纷',
                value: '10844',
                caseType: 'LITIGATION',
              },
              {
                label: '医疗损害责任纠纷',
                value: '10845',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '侵害患者知情同意权责任纠纷',
                    value: '10846',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '医疗产品责任纠纷',
                    value: '10847',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '环境污染责任纠纷',
                value: '10848',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '大气污染责任纠纷',
                    value: '10849',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '水污染责任纠纷',
                    value: '10850',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '噪声污染责任纠纷',
                    value: '10851',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '放射性污染责任纠纷',
                    value: '10852',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '土壤污染责任纠纷',
                    value: '10853',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '电子废物污染责任纠纷',
                    value: '10854',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '固体废物污染责任纠纷',
                    value: '10855',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '光污染责任纠纷',
                    value: '13361',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '高度危险责任纠纷',
                value: '10856',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '民用核设施、核材料损害责任纠纷',
                    value: '10857',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '民用航空器损害责任纠纷',
                    value: '10858',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '占有使用高度危险物损害责任纠纷',
                    value: '10859',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '高度危险活动损害责任纠纷',
                    value: '10860',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '遗失抛弃高度危险物损害责任纠纷',
                    value: '10861',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '非法占有高度危险物损害责任纠纷',
                    value: '10862',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '饲养动物损害责任纠纷',
                value: '10863',
                caseType: 'LITIGATION',
              },
              {
                label: '建筑物和物件损害责任纠纷',
                value: '10864',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '物件脱落坠落损害责任纠纷',
                    value: '10865',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '建筑物、构筑物倒塌、塌陷损害责任纠纷',
                    value: '10866',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '高空抛物、坠物损害责任纠纷',
                    value: '10867',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '堆放物倒塌、滚落、滑落损害责任纠纷',
                    value: '10868',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '公共道路妨碍通行损害责任纠纷',
                    value: '10869',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '林木折断、倾倒、果实坠落损害责任纠纷',
                    value: '10870',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '地面施工地下设施损害责任纠纷',
                    value: '10871',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '触电人身损害责任纠纷',
                value: '10872',
                caseType: 'LITIGATION',
              },
              {
                label: '义务帮工人受害责任纠纷',
                value: '10873',
                caseType: 'LITIGATION',
              },
              {
                label: '见义勇为人受害责任纠纷',
                value: '10874',
                caseType: 'LITIGATION',
              },
              {
                label: '公证损害责任纠纷',
                value: '10875',
                caseType: 'LITIGATION',
              },
              {
                label: '防卫过当损害责任纠纷',
                value: '10876',
                caseType: 'LITIGATION',
              },
              {
                label: '紧急避险损害责任纠纷',
                value: '10877',
                caseType: 'LITIGATION',
              },
              {
                label: '驻香港澳门特别行政区军人执行职务侵权责任纠纷',
                value: '10878',
                caseType: 'LITIGATION',
              },
              {
                label: '铁路运输损害责任纠纷',
                value: '10879',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '铁路运输人身损害责任纠纷',
                    value: '10880',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '铁路运输财产损害责任纠纷',
                    value: '10881',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '水上运输损害责任纠纷',
                value: '10882',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '水上运输人身损害责任纠纷',
                    value: '10883',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '水上运输财产损害责任纠纷',
                    value: '10884',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '航空运输损害责任纠纷',
                value: '10885',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '航空运输人身损害责任纠纷',
                    value: '10886',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '航空运输财产损害责任纠纷',
                    value: '10887',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '因申请财产保全损害责任纠纷',
                value: '10888',
                caseType: 'LITIGATION',
              },
              {
                label: '因申请证据保全损害责任纠纷',
                value: '10889',
                caseType: 'LITIGATION',
              },
              {
                label: '因申请先予执行损害责任纠纷',
                value: '10892',
                caseType: 'LITIGATION',
              },
              {
                label: '非机动车交通事故责任纠纷',
                value: '13312',
                caseType: 'LITIGATION',
              },
              {
                label: '生态破坏责任纠纷',
                value: '13313',
                caseType: 'LITIGATION',
              },
              {
                label: '因申请行为保全损害责任纠纷',
                value: '13314',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '非讼程序案件案由',
        value: '10894',
        caseType: 'LITIGATION',
        children: [
          {
            label: '选民资格案件',
            value: '10895',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请确定选民资格',
                value: '10896',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告公民失踪',
                value: '10897',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销宣告失踪',
                value: '10898',
                caseType: 'LITIGATION',
              },
              {
                label: '申请为失踪人财产指定变更代管人',
                value: '10899',
                caseType: 'LITIGATION',
              },
              {
                label: '失踪人债务支付纠纷',
                value: '10900',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告公民死亡',
                value: '10901',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销宣告公民死亡',
                value: '10902',
                caseType: 'LITIGATION',
              },
              {
                label: '被撤销死亡宣告人请求返还财产纠纷',
                value: '10903',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '宣告失踪、宣告死亡案件',
            value: '10904',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请宣告自然人失踪',
                value: '13315',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销宣告失踪判决',
                value: '13316',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告自然人死亡',
                value: '13318',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销宣告自然人死亡判决',
                value: '13319',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '认定自然人无民事行为能力、限制民事行为能力案件',
            value: '10905',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请宣告自然人无民事行为能力',
                value: '10906',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告自然人限制民事行为能力',
                value: '10907',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告自然人恢复限制民事行为能力',
                value: '10908',
                caseType: 'LITIGATION',
              },
              {
                label: '申请宣告自然人恢复完全民事行为能力',
                value: '10909',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '认定财产无主案件',
            value: '10910',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请认定财产无主',
                value: '10911',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销认定财产无主判决',
                value: '10912',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '监护权特别程序案件',
            value: '10913',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请确定监护人',
                value: '10914',
                caseType: 'LITIGATION',
              },
              {
                label: '申请变更监护人',
                value: '10915',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销监护人资格',
                value: '10916',
                caseType: 'LITIGATION',
              },
              {
                label: '申请指定监护人',
                value: '13326',
                caseType: 'LITIGATION',
              },
              {
                label: '申请恢复监护人资格',
                value: '13327',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '督促程序案件',
            value: '10917',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请支付令',
                value: '10918',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '公示催告程序案件',
            value: '10919',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请公示催告',
                value: '10920',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '申请诉前停止侵害知识产权案件',
            value: '10921',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请诉前停止侵害专利权',
                value: '10922',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前停止侵害注册商标专用权',
                value: '10923',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前停止侵害著作权',
                value: '10924',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前停止侵害植物新品种权',
                value: '10925',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前停止侵害计算机软件著作权',
                value: '13329',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前停止侵害集成电路布图设计专用权',
                value: '13330',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '申请保全案件',
            value: '10926',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请诉前财产保全',
                value: '10927',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前证据保全',
                value: '10929',
                caseType: 'LITIGATION',
              },
              {
                label: '仲裁程序中的财产保全',
                value: '10931',
                caseType: 'LITIGATION',
              },
              {
                label: '仲裁程序中的证据保全',
                value: '10932',
                caseType: 'LITIGATION',
              },
              {
                label: '申请中止支付信用证项下款项',
                value: '10933',
                caseType: 'LITIGATION',
              },
              {
                label: '申请中止支付保函项下款项',
                value: '10934',
                caseType: 'LITIGATION',
              },
              {
                label: '申请诉前行为保全',
                value: '13331',
                caseType: 'LITIGATION',
              },
              {
                label: '申请仲裁前财产保全',
                value: '13332',
                caseType: 'LITIGATION',
              },
              {
                label: '申请仲裁前证据保全',
                value: '13333',
                caseType: 'LITIGATION',
              },
              {
                label: '申请执行前财产保全',
                value: '13334',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '仲裁程序案件',
            value: '10935',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请确认仲裁协议效力',
                value: '10936',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销仲裁裁决',
                value: '10937',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '海事诉讼特别程序案件',
            value: '10938',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请海事请求保全',
                value: '10939',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '申请扣押船舶',
                    value: '10940',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请拍卖扣押船舶',
                    value: '10941',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请扣押船载货物',
                    value: '10942',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请拍卖扣押船载货物',
                    value: '10943',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请扣押船用燃油及船用物料',
                    value: '10944',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请拍卖扣押船用燃油及船用物料',
                    value: '10945',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '申请海事支付令',
                value: '10946',
                caseType: 'LITIGATION',
              },
              {
                label: '申请海事强制令',
                value: '10947',
                caseType: 'LITIGATION',
              },
              {
                label: '申请海事证据保全',
                value: '10948',
                caseType: 'LITIGATION',
              },
              {
                label: '申请设立海事赔偿责任限制基金',
                value: '10949',
                caseType: 'LITIGATION',
              },
              {
                label: '申请船舶优先权催告',
                value: '10950',
                caseType: 'LITIGATION',
              },
              {
                label: '申请海事债权登记与受偿',
                value: '10951',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '申请承认与执行法院判决、仲裁裁决案件',
            value: '10952',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请执行海事仲裁裁决',
                value: '10953',
                caseType: 'LITIGATION',
              },
              {
                label: '申请执行知识产权仲裁裁决',
                value: '10954',
                caseType: 'LITIGATION',
              },
              {
                label: '申请执行涉外仲裁裁决',
                value: '10955',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行香港特别行政区法院民事判决',
                value: '10956',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行香港特别行政区仲裁裁决',
                value: '10957',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行澳门特别行政区法院民事判决',
                value: '10958',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行澳门特别行政区仲裁裁决',
                value: '10959',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行台湾地区法院民事判决',
                value: '10960',
                caseType: 'LITIGATION',
              },
              {
                label: '申请认可和执行台湾地区仲裁裁决',
                value: '10961',
                caseType: 'LITIGATION',
              },
              {
                label: '申请承认和执行外国法院民事判决裁定',
                value: '10962',
                caseType: 'LITIGATION',
              },
              {
                label: '申请承认和执行外国仲裁裁决',
                value: '10963',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '指定遗产管理人案件',
            value: '11825',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请指定遗产管理人',
                value: '13321',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '确认调解协议案件',
            value: '11826',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请司法确认调解协议',
                value: '13322',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销确认调解协议裁定',
                value: '13323',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '实现担保物权案件',
            value: '11827',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请实现担保物权',
                value: '13324',
                caseType: 'LITIGATION',
              },
              {
                label: '申请撤销准许实现担保物权裁定',
                value: '13325',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '公司清算案件',
            value: '11829',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请公司清算',
                value: '10709',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '破产程序案件',
            value: '11830',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请破产清算',
                value: '10717',
                caseType: 'LITIGATION',
              },
              {
                label: '申请破产重整',
                value: '10718',
                caseType: 'LITIGATION',
              },
              {
                label: '申请破产和解',
                value: '10719',
                caseType: 'LITIGATION',
              },
              {
                label: '申请对破产财产追加分配',
                value: '13328',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '申请人身安全保护令案件',
            value: '11831',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请人身安全保护令',
                value: '13335',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '申请人格权侵害禁令案件',
            value: '11832',
            caseType: 'LITIGATION',
            children: [
              {
                label: '申请人格权侵害禁令',
                value: '13336',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '特殊诉讼程序案件案由',
        value: '13280',
        caseType: 'LITIGATION',
        children: [
          {
            label: '执行程序中的异议之诉 ',
            value: '10964',
            caseType: 'LITIGATION',
            children: [
              {
                label: '执行分配方案异议之诉',
                value: '10967',
                caseType: 'LITIGATION',
              },
              {
                label: '执行异议之诉',
                value: '13342',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '案外人执行异议之诉',
                    value: '10965',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '申请执行人执行异议之诉',
                    value: '10966',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '追加、变更被执行人异议之诉',
                value: '13343',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '与宣告失踪、宣告死亡案件有关的纠纷',
            value: '13283',
            caseType: 'LITIGATION',
            children: [
              {
                label: '失踪人债务支付纠纷',
                value: '13317',
                caseType: 'LITIGATION',
              },
              {
                label: '被撤销死亡宣告人请求返还财产纠纷',
                value: '13320',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '公益诉讼',
            value: '13284',
            caseType: 'LITIGATION',
            children: [
              {
                label: '生态环境保护民事公益诉讼',
                value: '13337',
                caseType: 'LITIGATION',
                children: [
                  {
                    label: '环境污染民事公益诉讼',
                    value: '13362',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '生态破坏民事公益诉讼',
                    value: '13363',
                    caseType: 'LITIGATION',
                  },
                  {
                    label: '生态环境损害赔偿诉讼',
                    value: '13364',
                    caseType: 'LITIGATION',
                  },
                ],
              },
              {
                label: '英雄烈士保护民事公益诉讼',
                value: '13338',
                caseType: 'LITIGATION',
              },
              {
                label: '未成年人保护民事公益诉讼',
                value: '13339',
                caseType: 'LITIGATION',
              },
              {
                label: '消费者权益保护民事公益诉讼',
                value: '13340',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '第三人撤销之诉',
            value: '13285',
            caseType: 'LITIGATION',
            children: [
              {
                label: '第三人撤销之诉',
                value: '13341',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: '刑事',
    value: '10968',
    caseType: 'LITIGATION',
    children: [
      {
        label: '危害国家安全罪',
        value: '10969',
        caseType: 'LITIGATION',
        children: [
          {
            label: '背叛国家罪',
            value: '10970',
            caseType: 'LITIGATION',
          },
          {
            label: '分裂国家罪',
            value: '10971',
            caseType: 'LITIGATION',
          },
          {
            label: '煽动分裂国家罪',
            value: '10972',
            caseType: 'LITIGATION',
          },
          {
            label: '颠覆国家政权罪',
            value: '10974',
            caseType: 'LITIGATION',
          },
          {
            label: '煽动颠覆国家政权罪',
            value: '10975',
            caseType: 'LITIGATION',
          },
          {
            label: '资助危害国家安全犯罪活动罪',
            value: '10976',
            caseType: 'LITIGATION',
          },
          {
            label: '投敌叛变罪',
            value: '10977',
            caseType: 'LITIGATION',
          },
          {
            label: '叛逃罪',
            value: '10978',
            caseType: 'LITIGATION',
          },
          {
            label: '间谍罪',
            value: '10979',
            caseType: 'LITIGATION',
          },
          {
            label: '资敌罪',
            value: '10981',
            caseType: 'LITIGATION',
          },
          {
            label: '武装叛乱、暴乱罪',
            value: '13519',
            caseType: 'LITIGATION',
          },
          {
            label: '为境外窃取、刺探、收买、非法提供国家秘密、情报罪',
            value: '13520',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '危害公共安全罪',
        value: '10982',
        caseType: 'LITIGATION',
        children: [
          {
            label: '放火罪',
            value: '10983',
            caseType: 'LITIGATION',
          },
          {
            label: '决水罪',
            value: '10984',
            caseType: 'LITIGATION',
          },
          {
            label: '爆炸罪',
            value: '10985',
            caseType: 'LITIGATION',
          },
          {
            label: '投放危险物质罪',
            value: '10986',
            caseType: 'LITIGATION',
          },
          {
            label: '以危险方法危害公共安全罪',
            value: '10987',
            caseType: 'LITIGATION',
          },
          {
            label: '失火罪',
            value: '10988',
            caseType: 'LITIGATION',
          },
          {
            label: '过失决水罪',
            value: '10989',
            caseType: 'LITIGATION',
          },
          {
            label: '过失爆炸罪',
            value: '10990',
            caseType: 'LITIGATION',
          },
          {
            label: '过失投放危险物质罪',
            value: '10991',
            caseType: 'LITIGATION',
          },
          {
            label: '过失以危险方法危害公共安全罪',
            value: '10992',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏交通工具罪',
            value: '10993',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏交通设施罪',
            value: '10994',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏电力设备罪',
            value: '10995',
            caseType: 'LITIGATION',
          },
          {
            label: '帮助恐怖活动罪',
            value: '11002',
            caseType: 'LITIGATION',
          },
          {
            label: '准备实施恐怖活动罪',
            value: '11003',
            caseType: 'LITIGATION',
          },
          {
            label: '利用极端主义破坏法律实施罪',
            value: '11005',
            caseType: 'LITIGATION',
          },
          {
            label: '劫持航空器罪',
            value: '11008',
            caseType: 'LITIGATION',
          },
          {
            label: '暴力危及飞行安全罪',
            value: '11010',
            caseType: 'LITIGATION',
          },
          {
            label: '丢失枪支不报罪',
            value: '11021',
            caseType: 'LITIGATION',
          },
          {
            label: '重大飞行事故罪',
            value: '11023',
            caseType: 'LITIGATION',
          },
          {
            label: '铁路运营安全事故罪',
            value: '11024',
            caseType: 'LITIGATION',
          },
          {
            label: '交通肇事罪',
            value: '11025',
            caseType: 'LITIGATION',
          },
          {
            label: '危险驾驶罪',
            value: '11026',
            caseType: 'LITIGATION',
          },
          {
            label: '重大责任事故罪',
            value: '11027',
            caseType: 'LITIGATION',
          },
          {
            label: '强令、组织违章冒险作业罪',
            value: '11028',
            caseType: 'LITIGATION',
          },
          {
            label: '重大劳动安全事故罪',
            value: '11029',
            caseType: 'LITIGATION',
          },
          {
            label: '大型群众性活动重大安全事故罪',
            value: '11030',
            caseType: 'LITIGATION',
          },
          {
            label: '危险物品肇事罪',
            value: '11031',
            caseType: 'LITIGATION',
          },
          {
            label: '工程重大安全事故罪',
            value: '11032',
            caseType: 'LITIGATION',
          },
          {
            label: '教育设施重大安全事故罪',
            value: '11033',
            caseType: 'LITIGATION',
          },
          {
            label: '消防责任事故罪',
            value: '11034',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏易燃易爆设备罪',
            value: '13523',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏交通工具罪',
            value: '13524',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏交通设施罪',
            value: '13525',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏电力设备罪',
            value: '13526',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏易燃易爆设备罪',
            value: '13527',
            caseType: 'LITIGATION',
          },
          {
            label: '组织、领导、参加恐怖组织罪',
            value: '13528',
            caseType: 'LITIGATION',
          },
          {
            label: '宣扬恐怖主义、极端主义、煽动实施恐怖活动罪',
            value: '13530',
            caseType: 'LITIGATION',
          },
          {
            label: '强制穿戴宣扬恐怖主义、极端主义服饰、标志罪',
            value: '13531',
            caseType: 'LITIGATION',
          },
          {
            label: '非法持有宣扬恐怖主义、极端主义物品罪',
            value: '13532',
            caseType: 'LITIGATION',
          },
          {
            label: '劫持船只、汽车罪',
            value: '13533',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏广播电视设施、公用电信设施罪',
            value: '13534',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏广播电视设施、公用电信设施罪',
            value: '13535',
            caseType: 'LITIGATION',
          },
          {
            label: '非法制造、买卖、运输、邮寄、储存枪支、弹药、爆炸物罪',
            value: '13536',
            caseType: 'LITIGATION',
          },
          {
            label: '非法制造、买卖、运输、储存危险物质罪',
            value: '13538',
            caseType: 'LITIGATION',
          },
          {
            label: '违规制造、销售枪支罪',
            value: '13539',
            caseType: 'LITIGATION',
          },
          {
            label: '盗窃、抢夺枪支、弹药、爆炸物、危险物质罪',
            value: '13541',
            caseType: 'LITIGATION',
          },
          {
            label: '抢劫枪支、弹药、爆炸物、危险物质罪',
            value: '13543',
            caseType: 'LITIGATION',
          },
          {
            label: '非法持有、私藏枪支、弹药罪',
            value: '13544',
            caseType: 'LITIGATION',
          },
          {
            label: '非法出租、出借枪支罪',
            value: '13545',
            caseType: 'LITIGATION',
          },
          {
            label: '非法携带枪支、弹药、管制刀具、危险物品危及公共安全罪',
            value: '13546',
            caseType: 'LITIGATION',
          },
          {
            label: '不报、谎报安全事故罪',
            value: '13547',
            caseType: 'LITIGATION',
          },
          {
            label: '妨害安全驾驶罪',
            value: '13901',
            caseType: 'LITIGATION',
          },
          {
            label: '危险作业罪',
            value: '13902',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '破坏社会主义市场经济秩序罪',
        value: '11036',
        caseType: 'LITIGATION',
        children: [
          {
            label: '走私罪',
            value: '11046',
            caseType: 'LITIGATION',
            children: [
              {
                label: '走私文物罪',
                value: '11048',
                caseType: 'LITIGATION',
              },
              {
                label: '走私淫秽物品罪',
                value: '11052',
                caseType: 'LITIGATION',
              },
              {
                label: '走私武器、弹药罪',
                value: '13668',
                caseType: 'LITIGATION',
              },
              {
                label: '走私核材料罪',
                value: '13669',
                caseType: 'LITIGATION',
              },
              {
                label: '走私假币罪',
                value: '13670',
                caseType: 'LITIGATION',
              },
              {
                label: '走私贵重金属罪',
                value: '13671',
                caseType: 'LITIGATION',
              },
              {
                label: '走私珍贵动物、珍贵动物制品罪',
                value: '13672',
                caseType: 'LITIGATION',
              },
              {
                label: '走私珍稀植物、珍稀植物制品罪',
                value: '13673',
                caseType: 'LITIGATION',
              },
              {
                label: '走私国家禁止进出口的货物、物品罪',
                value: '13674',
                caseType: 'LITIGATION',
              },
              {
                label: '走私废物罪',
                value: '13675',
                caseType: 'LITIGATION',
              },
              {
                label: '走私固体废物罪',
                value: '13676',
                caseType: 'LITIGATION',
              },
              {
                label: '走私普通货物、物品罪',
                value: '13677',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '破坏金融管理秩序罪',
            value: '11072',
            caseType: 'LITIGATION',
            children: [
              {
                label: '伪造货币罪',
                value: '11073',
                caseType: 'LITIGATION',
              },
              {
                label: '变造货币罪',
                value: '11077',
                caseType: 'LITIGATION',
              },
              {
                label: '擅自设立金融机构罪',
                value: '11078',
                caseType: 'LITIGATION',
              },
              {
                label: '高利转贷罪',
                value: '11080',
                caseType: 'LITIGATION',
              },
              {
                label: '非法吸收公众存款罪',
                value: '11082',
                caseType: 'LITIGATION',
              },
              {
                label: '利用未公开信息交易罪',
                value: '11088',
                caseType: 'LITIGATION',
              },
              {
                label: '背信运用受托财产罪',
                value: '11091',
                caseType: 'LITIGATION',
              },
              {
                label: '违法运用资金罪',
                value: '11092',
                caseType: 'LITIGATION',
              },
              {
                label: '违法发放贷款罪',
                value: '11093',
                caseType: 'LITIGATION',
              },
              {
                label: '吸收客户资金不入账罪',
                value: '11094',
                caseType: 'LITIGATION',
              },
              {
                label: '违规出具金融票证罪',
                value: '11095',
                caseType: 'LITIGATION',
              },
              {
                label: '逃汇罪',
                value: '11097',
                caseType: 'LITIGATION',
              },
              {
                label: '洗钱罪',
                value: '11098',
                caseType: 'LITIGATION',
              },
              {
                label: '出售、购买、运输假币罪',
                value: '13703',
                caseType: 'LITIGATION',
              },
              {
                label: '金融工作人员购买假币、以假币换取货币罪',
                value: '13704',
                caseType: 'LITIGATION',
              },
              {
                label: '持有、使用假币罪',
                value: '13705',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造、转让金融机构经营许可证、批准文件罪',
                value: '13706',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造、转让金融机构经营许可证罪',
                value: '13707',
                caseType: 'LITIGATION',
              },
              {
                label: '骗取贷款、票据承兑、金融票证罪',
                value: '13708',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造金融票证罪',
                value: '13709',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害信用卡管理罪',
                value: '13710',
                caseType: 'LITIGATION',
              },
              {
                label: '窃取、收买、非法提供信用卡信息罪',
                value: '13711',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造国家有价证券罪',
                value: '13712',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造股票、公司、企业债券罪',
                value: '13713',
                caseType: 'LITIGATION',
              },
              {
                label: '擅自发行股票、公司、企业债券罪',
                value: '13714',
                caseType: 'LITIGATION',
              },
              {
                label: '内幕交易、泄露内幕信息罪',
                value: '13715',
                caseType: 'LITIGATION',
              },
              {
                label: '编造并传播证券、期货交易虚假信息罪',
                value: '13716',
                caseType: 'LITIGATION',
              },
              {
                label: '编造并传播证券交易虚假信息罪',
                value: '13717',
                caseType: 'LITIGATION',
              },
              {
                label: '诱骗投资者买卖证券、期货合约罪',
                value: '13718',
                caseType: 'LITIGATION',
              },
              {
                label: '诱骗投资者买卖证券罪',
                value: '13719',
                caseType: 'LITIGATION',
              },
              {
                label: '操纵证券、期货交易价格罪',
                value: '13720',
                caseType: 'LITIGATION',
              },
              {
                label: '操纵证券交易价格罪',
                value: '13721',
                caseType: 'LITIGATION',
              },
              {
                label: '操纵证券、期货市场罪',
                value: '13722',
                caseType: 'LITIGATION',
              },
              {
                label: '违法向关系人发放贷款罪',
                value: '13723',
                caseType: 'LITIGATION',
              },
              {
                label: '用账外客户资金非法拆借、发放贷款罪',
                value: '13724',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出具金融票证罪',
                value: '13725',
                caseType: 'LITIGATION',
              },
              {
                label: '对违法票据承兑、付款、保证罪',
                value: '13726',
                caseType: 'LITIGATION',
              },
              {
                label: '骗购外汇罪',
                value: '13727',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '金融诈骗罪',
            value: '11099',
            caseType: 'LITIGATION',
            children: [
              {
                label: '集资诈骗罪',
                value: '11100',
                caseType: 'LITIGATION',
              },
              {
                label: '贷款诈骗罪',
                value: '11101',
                caseType: 'LITIGATION',
              },
              {
                label: '票据诈骗罪',
                value: '11102',
                caseType: 'LITIGATION',
              },
              {
                label: '信用证诈骗罪',
                value: '11103',
                caseType: 'LITIGATION',
              },
              {
                label: '信用卡诈骗罪',
                value: '11104',
                caseType: 'LITIGATION',
              },
              {
                label: '有价证券诈骗罪',
                value: '11105',
                caseType: 'LITIGATION',
              },
              {
                label: '保险诈骗罪',
                value: '11106',
                caseType: 'LITIGATION',
              },
              {
                label: '金融凭证诈骗罪',
                value: '13728',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '危害税收征管罪',
            value: '11107',
            caseType: 'LITIGATION',
            children: [
              {
                label: '逃税罪',
                value: '11108',
                caseType: 'LITIGATION',
              },
              {
                label: '抗税罪',
                value: '11109',
                caseType: 'LITIGATION',
              },
              {
                label: '逃避追缴欠税罪',
                value: '11110',
                caseType: 'LITIGATION',
              },
              {
                label: '骗取出口退税罪',
                value: '11111',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出售增值税专用发票罪',
                value: '11115',
                caseType: 'LITIGATION',
              },
              {
                label: '持有伪造的发票罪',
                value: '11120',
                caseType: 'LITIGATION',
              },
              {
                label: '偷税罪',
                value: '13729',
                caseType: 'LITIGATION',
              },
              {
                label: '虚开增值税专用发票、用于骗取出口退税、抵扣税款发票罪',
                value: '13730',
                caseType: 'LITIGATION',
              },
              {
                label: '虚开发票罪',
                value: '13731',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、出售伪造的增值税专用发票罪',
                value: '13732',
                caseType: 'LITIGATION',
              },
              {
                label: '非法购买增值税专用发票、购买伪造的增值税专用发票罪',
                value: '13733',
                caseType: 'LITIGATION',
              },
              {
                label: '非法制造、出售非法制造的用于骗取出口退税、抵扣税款发票罪',
                value: '13734',
                caseType: 'LITIGATION',
              },
              {
                label: '非法制造、出售非法制造的发票罪',
                value: '13735',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出售用于骗取出口退税、抵扣税款发票罪',
                value: '13736',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出售发票罪',
                value: '13737',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '侵犯知识产权罪',
            value: '11121',
            caseType: 'LITIGATION',
            children: [
              {
                label: '假冒注册商标罪',
                value: '11122',
                caseType: 'LITIGATION',
              },
              {
                label: '销售假冒注册商标的商品罪',
                value: '11123',
                caseType: 'LITIGATION',
              },
              {
                label: '假冒专利罪',
                value: '11125',
                caseType: 'LITIGATION',
              },
              {
                label: '侵犯著作权罪',
                value: '11126',
                caseType: 'LITIGATION',
              },
              {
                label: '侵犯商业秘密罪',
                value: '11127',
                caseType: 'LITIGATION',
              },
              {
                label: '非法制造、销售非法制造的注册商标标识罪',
                value: '13738',
                caseType: 'LITIGATION',
              },
              {
                label: '销售侵权复制品罪',
                value: '13739',
                caseType: 'LITIGATION',
              },
              {
                label: '为境外窃取、刺探、收买、非法提供商业秘密罪',
                value: '13906',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '扰乱市场秩序罪',
            value: '11128',
            caseType: 'LITIGATION',
            children: [
              {
                label: '虚假广告罪',
                value: '11130',
                caseType: 'LITIGATION',
              },
              {
                label: '串通投标罪',
                value: '11131',
                caseType: 'LITIGATION',
              },
              {
                label: '合同诈骗罪',
                value: '11132',
                caseType: 'LITIGATION',
              },
              {
                label: '非法经营罪',
                value: '11134',
                caseType: 'LITIGATION',
              },
              {
                label: '强迫交易罪',
                value: '11135',
                caseType: 'LITIGATION',
              },
              {
                label: '出具证明文件重大失实罪',
                value: '11139',
                caseType: 'LITIGATION',
              },
              {
                label: '逃避商检罪',
                value: '11140',
                caseType: 'LITIGATION',
              },
              {
                label: '损害商业信誉、商品声誉罪',
                value: '13740',
                caseType: 'LITIGATION',
              },
              {
                label: '组织、领导传销活动罪',
                value: '13741',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、倒卖伪造的有价票证罪',
                value: '13742',
                caseType: 'LITIGATION',
              },
              {
                label: '倒卖车票、船票罪',
                value: '13743',
                caseType: 'LITIGATION',
              },
              {
                label: '非法转让、倒卖土地使用权罪',
                value: '13744',
                caseType: 'LITIGATION',
              },
              {
                label: '中介组织人员提供虚假证明文件罪',
                value: '13745',
                caseType: 'LITIGATION',
              },
              {
                label: '中介组织人员出具证明文件重大失实罪',
                value: '13746',
                caseType: 'LITIGATION',
              },
              {
                label: '提供虚假证明文件罪',
                value: '13747',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '生产、销售伪劣商品罪',
            value: '13548',
            caseType: 'LITIGATION',
            children: [
              {
                label: '生产、销售伪劣产品罪',
                value: '13658',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售假药罪',
                value: '13659',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售劣药罪',
                value: '13660',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售不符合卫生标准的食品罪',
                value: '13661',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售不符合安全标准的食品罪',
                value: '13662',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售有毒、有害食品罪',
                value: '13663',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售不符合标准的医用器材罪',
                value: '13664',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售不符合安全标准的产品罪',
                value: '13665',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售伪劣农药、兽药、化肥、种子罪',
                value: '13666',
                caseType: 'LITIGATION',
              },
              {
                label: '生产、销售不符合卫生标准的化妆品罪',
                value: '13667',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '妨害对公司、企业的管理秩序罪',
            value: '13549',
            caseType: 'LITIGATION',
            children: [
              {
                label: '虚报注册资本罪',
                value: '13678',
                caseType: 'LITIGATION',
              },
              {
                label: '虚假出资、抽逃出资罪',
                value: '13679',
                caseType: 'LITIGATION',
              },
              {
                label: '欺诈发行证券罪',
                value: '13680',
                caseType: 'LITIGATION',
              },
              {
                label: '提供虚假财会报告罪',
                value: '13681',
                caseType: 'LITIGATION',
              },
              {
                label: '违规披露、不披露重要信息罪',
                value: '13682',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害清算罪',
                value: '13683',
                caseType: 'LITIGATION',
              },
              {
                label: '隐匿、故意销毁会计资料罪',
                value: '13684',
                caseType: 'LITIGATION',
              },
              {
                label: '隐匿、故意销毁会计凭证、会计账簿、财务会计报告罪',
                value: '13685',
                caseType: 'LITIGATION',
              },
              {
                label: '虚假破产罪',
                value: '13686',
                caseType: 'LITIGATION',
              },
              {
                label: '公司、企业人员受贿罪',
                value: '13687',
                caseType: 'LITIGATION',
              },
              {
                label: '非国家工作人员受贿罪',
                value: '13688',
                caseType: 'LITIGATION',
              },
              {
                label: '对公司、企业人员行贿罪',
                value: '13689',
                caseType: 'LITIGATION',
              },
              {
                label: '对非国家工作人员行贿罪',
                value: '13690',
                caseType: 'LITIGATION',
              },
              {
                label: '对外国公职人员、国际公共组织官员行贿罪',
                value: '13691',
                caseType: 'LITIGATION',
              },
              {
                label: '非法经营同类营业罪',
                value: '13692',
                caseType: 'LITIGATION',
              },
              {
                label: '为亲友非法牟利罪',
                value: '13693',
                caseType: 'LITIGATION',
              },
              {
                label: '签订、履行合同失职罪',
                value: '13694',
                caseType: 'LITIGATION',
              },
              {
                label: '签订、履行合同失职被骗罪',
                value: '13695',
                caseType: 'LITIGATION',
              },
              {
                label: '国有公司、企业、事业单位人员失职罪',
                value: '13696',
                caseType: 'LITIGATION',
              },
              {
                label: '国有公司、企业、事业单位人员滥用职权罪',
                value: '13697',
                caseType: 'LITIGATION',
              },
              {
                label: '徇私舞弊造成破产、亏损罪',
                value: '13698',
                caseType: 'LITIGATION',
              },
              {
                label: '徇私舞弊低价折股、出售国有资产罪',
                value: '13699',
                caseType: 'LITIGATION',
              },
              {
                label: '操纵上市公司罪',
                value: '13700',
                caseType: 'LITIGATION',
              },
              {
                label: '指使操纵上市公司罪',
                value: '13701',
                caseType: 'LITIGATION',
              },
              {
                label: '背信损害上市公司利益罪',
                value: '13702',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '侵犯财产罪',
        value: '11178',
        caseType: 'LITIGATION',
        children: [
          {
            label: '抢劫罪',
            value: '11179',
            caseType: 'LITIGATION',
          },
          {
            label: '盗窃罪',
            value: '11180',
            caseType: 'LITIGATION',
          },
          {
            label: '诈骗罪',
            value: '11181',
            caseType: 'LITIGATION',
          },
          {
            label: '抢夺罪',
            value: '11182',
            caseType: 'LITIGATION',
          },
          {
            label: '职务侵占罪',
            value: '11183',
            caseType: 'LITIGATION',
          },
          {
            label: '挪用资金罪',
            value: '11184',
            caseType: 'LITIGATION',
          },
          {
            label: '挪用特定款物罪',
            value: '11185',
            caseType: 'LITIGATION',
          },
          {
            label: '敲诈勒索罪',
            value: '11186',
            caseType: 'LITIGATION',
          },
          {
            label: '故意毁坏财物罪',
            value: '11187',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏生产经营罪',
            value: '11188',
            caseType: 'LITIGATION',
          },
          {
            label: '拒不支付劳动报酬罪',
            value: '11189',
            caseType: 'LITIGATION',
          },
          {
            label: '聚众哄抢罪',
            value: '13597',
            caseType: 'LITIGATION',
          },
          {
            label: '侵占罪',
            value: '13598',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '妨害社会管理秩序罪',
        value: '11190',
        caseType: 'LITIGATION',
        children: [
          {
            label: '扰乱公共秩序罪',
            value: '13599',
            caseType: 'LITIGATION',
            children: [
              {
                label: '妨害公务罪',
                value: '13748',
                caseType: 'LITIGATION',
              },
              {
                label: '煽动暴力抗拒法律实施罪',
                value: '13749',
                caseType: 'LITIGATION',
              },
              {
                label: '招摇撞骗罪',
                value: '13750',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造、买卖国家机关公文、证件、印章罪',
                value: '13751',
                caseType: 'LITIGATION',
              },
              {
                label: '盗窃、抢夺、毁灭国家机关公文、证件、印章罪',
                value: '13752',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造公司、企业、事业单位、人民团体印章罪',
                value: '13753',
                caseType: 'LITIGATION',
              },
              {
                label: '伪造、变造、买卖身份证件罪',
                value: '13755',
                caseType: 'LITIGATION',
              },
              {
                label: '使用虚假身份证件、盗用身份证件罪',
                value: '13756',
                caseType: 'LITIGATION',
              },
              {
                label: '非法生产、买卖警用装备罪',
                value: '13757',
                caseType: 'LITIGATION',
              },
              {
                label: '非法获取国家秘密罪',
                value: '13758',
                caseType: 'LITIGATION',
              },
              {
                label: '非法持有国家绝密、机密文件、资料、物品罪',
                value: '13759',
                caseType: 'LITIGATION',
              },
              {
                label: '非法生产、销售专用间谍器材、窃听、窃照专用器材罪',
                value: '13761',
                caseType: 'LITIGATION',
              },
              {
                label: '非法使用窃听、窃照专用器材罪',
                value: '13762',
                caseType: 'LITIGATION',
              },
              {
                label: '组织考试作弊罪',
                value: '13763',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出售、提供试题、答案罪',
                value: '13764',
                caseType: 'LITIGATION',
              },
              {
                label: '代替考试罪',
                value: '13765',
                caseType: 'LITIGATION',
              },
              {
                label: '非法侵入计算机信息系统罪',
                value: '13766',
                caseType: 'LITIGATION',
              },
              {
                label: '非法获取计算机信息系统数据、非法控制计算机信息系统罪',
                value: '13767',
                caseType: 'LITIGATION',
              },
              {
                label: '提供侵入、非法控制计算机信息系统程序、工具罪',
                value: '13768',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏计算机信息系统罪',
                value: '13769',
                caseType: 'LITIGATION',
              },
              {
                label: '拒不履行信息网络安全管理义务罪',
                value: '13770',
                caseType: 'LITIGATION',
              },
              {
                label: '非法利用信息网络罪',
                value: '13771',
                caseType: 'LITIGATION',
              },
              {
                label: '帮助信息网络犯罪活动罪',
                value: '13772',
                caseType: 'LITIGATION',
              },
              {
                label: '扰乱无线电通讯管理秩序罪',
                value: '13773',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众扰乱社会秩序罪',
                value: '13774',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众冲击国家机关罪',
                value: '13775',
                caseType: 'LITIGATION',
              },
              {
                label: '扰乱国家机关工作秩序罪',
                value: '13776',
                caseType: 'LITIGATION',
              },
              {
                label: '组织、资助非法聚集罪',
                value: '13777',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众扰乱公共场所秩序、交通秩序罪',
                value: '13778',
                caseType: 'LITIGATION',
              },
              {
                label: '投放虚假危险物质罪',
                value: '13779',
                caseType: 'LITIGATION',
              },
              {
                label: '编造、故意传播虚假恐怖信息罪',
                value: '13780',
                caseType: 'LITIGATION',
              },
              {
                label: '编造、故意传播虚假信息罪',
                value: '13781',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众斗殴罪',
                value: '13782',
                caseType: 'LITIGATION',
              },
              {
                label: '寻衅滋事罪',
                value: '13783',
                caseType: 'LITIGATION',
              },
              {
                label: '组织、领导、参加黑社会性质组织罪',
                value: '13784',
                caseType: 'LITIGATION',
              },
              {
                label: '入境发展黑社会组织罪',
                value: '13785',
                caseType: 'LITIGATION',
              },
              {
                label: '包庇、纵容黑社会性质组织罪',
                value: '13786',
                caseType: 'LITIGATION',
              },
              {
                label: '传授犯罪方法罪',
                value: '13787',
                caseType: 'LITIGATION',
              },
              {
                label: '非法集会、游行、示威罪',
                value: '13788',
                caseType: 'LITIGATION',
              },
              {
                label: '非法携带武器、管制刀具、爆炸物参加集会、游行、示威罪',
                value: '13789',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏集会、游行、示威罪',
                value: '13790',
                caseType: 'LITIGATION',
              },
              {
                label: '侮辱国旗、国徽、国歌罪',
                value: '13791',
                caseType: 'LITIGATION',
              },
              {
                label: '组织、利用会道门、邪教组织、利用迷信破坏法律实施罪',
                value: '13792',
                caseType: 'LITIGATION',
              },
              {
                label: '组织、利用会道门、邪教组织、利用迷信致人重伤、死亡罪',
                value: '13794',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众淫乱罪',
                value: '13795',
                caseType: 'LITIGATION',
              },
              {
                label: '引诱未成年人聚众淫乱罪',
                value: '13796',
                caseType: 'LITIGATION',
              },
              {
                label: '盗窃、侮辱、故意毁坏尸体、尸骨、骨灰罪',
                value: '13798',
                caseType: 'LITIGATION',
              },
              {
                label: '赌博罪',
                value: '13799',
                caseType: 'LITIGATION',
              },
              {
                label: '开设赌场罪',
                value: '13800',
                caseType: 'LITIGATION',
              },
              {
                label: '故意延误投递邮件罪',
                value: '13801',
                caseType: 'LITIGATION',
              },
              {
                label: '袭警罪',
                value: '13912',
                caseType: 'LITIGATION',
              },
              {
                label: '冒名顶替罪',
                value: '13913',
                caseType: 'LITIGATION',
              },
              {
                label: '高空抛物罪',
                value: '13914',
                caseType: 'LITIGATION',
              },
              {
                label: '催收非法债务罪',
                value: '13915',
                caseType: 'LITIGATION',
              },
              {
                label: '侵害英雄烈士名誉、荣誉罪',
                value: '13916',
                caseType: 'LITIGATION',
              },
              {
                label: '组织参与国（境）外赌博罪',
                value: '13917',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '妨害司法罪',
            value: '13600',
            caseType: 'LITIGATION',
            children: [
              {
                label: '伪证罪',
                value: '13802',
                caseType: 'LITIGATION',
              },
              {
                label: '辩护人、诉讼代理人毁灭证据、伪造证据、妨害作证罪',
                value: '13803',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害作证罪',
                value: '13804',
                caseType: 'LITIGATION',
              },
              {
                label: '帮助毁灭、伪造证据罪',
                value: '13805',
                caseType: 'LITIGATION',
              },
              {
                label: '虚假诉讼罪',
                value: '13806',
                caseType: 'LITIGATION',
              },
              {
                label: '打击报复证人罪',
                value: '13807',
                caseType: 'LITIGATION',
              },
              {
                label: '泄露不应公开的案件信息罪',
                value: '13808',
                caseType: 'LITIGATION',
              },
              {
                label: '披露、报道不应公开的案件信息罪',
                value: '13809',
                caseType: 'LITIGATION',
              },
              {
                label: '扰乱法庭秩序罪',
                value: '13810',
                caseType: 'LITIGATION',
              },
              {
                label: '窝藏、包庇罪',
                value: '13811',
                caseType: 'LITIGATION',
              },
              {
                label: '拒绝提供间谍犯罪、恐怖主义犯罪、极端主义犯罪证据罪',
                value: '13813',
                caseType: 'LITIGATION',
              },
              {
                label: '掩饰、隐瞒犯罪所得、犯罪所得收益罪',
                value: '13816',
                caseType: 'LITIGATION',
              },
              {
                label: '拒不执行判决、裁定罪',
                value: '13817',
                caseType: 'LITIGATION',
              },
              {
                label: '非法处置查封、扣押、冻结的财产罪',
                value: '13818',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏监管秩序罪',
                value: '13819',
                caseType: 'LITIGATION',
              },
              {
                label: '脱逃罪',
                value: '13820',
                caseType: 'LITIGATION',
              },
              {
                label: '劫夺被押解人员罪',
                value: '13821',
                caseType: 'LITIGATION',
              },
              {
                label: '组织越狱罪',
                value: '13822',
                caseType: 'LITIGATION',
              },
              {
                label: '暴动越狱罪',
                value: '13823',
                caseType: 'LITIGATION',
              },
              {
                label: '聚众持械劫狱罪',
                value: '13824',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '妨害国(边)境管理罪',
            value: '13601',
            caseType: 'LITIGATION',
            children: [
              {
                label: '组织他人偷越国(边)境罪',
                value: '13825',
                caseType: 'LITIGATION',
              },
              {
                label: '骗取出境证件罪',
                value: '13826',
                caseType: 'LITIGATION',
              },
              {
                label: '提供伪造、变造的出入境证件罪',
                value: '13827',
                caseType: 'LITIGATION',
              },
              {
                label: '出售出入境证件罪',
                value: '13828',
                caseType: 'LITIGATION',
              },
              {
                label: '运送他人偷越国(边)境罪',
                value: '13829',
                caseType: 'LITIGATION',
              },
              {
                label: '偷越国(边)境罪',
                value: '13830',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏界碑、界桩罪',
                value: '13831',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏永久性测量标志罪',
                value: '13832',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '妨害文物管理罪',
            value: '13602',
            caseType: 'LITIGATION',
            children: [
              {
                label: '故意损毁文物罪',
                value: '13833',
                caseType: 'LITIGATION',
              },
              {
                label: '故意损毁名胜古迹罪',
                value: '13834',
                caseType: 'LITIGATION',
              },
              {
                label: '过失损毁文物罪',
                value: '13835',
                caseType: 'LITIGATION',
              },
              {
                label: '非法向外国人出售、赠送珍贵文物罪',
                value: '13836',
                caseType: 'LITIGATION',
              },
              {
                label: '倒卖文物罪',
                value: '13837',
                caseType: 'LITIGATION',
              },
              {
                label: '非法出售、私赠文物藏品罪',
                value: '13838',
                caseType: 'LITIGATION',
              },
              {
                label: '盗掘古文化遗址、古墓葬罪',
                value: '13839',
                caseType: 'LITIGATION',
              },
              {
                label: '盗掘古人类化石、古脊椎动物化石罪',
                value: '13840',
                caseType: 'LITIGATION',
              },
              {
                label: '抢夺、窃取国有档案罪',
                value: '13841',
                caseType: 'LITIGATION',
              },
              {
                label: '擅自出卖、转让国有档案罪',
                value: '13842',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '危害公共卫生罪',
            value: '13603',
            caseType: 'LITIGATION',
            children: [
              {
                label: '妨害传染病防治罪',
                value: '13844',
                caseType: 'LITIGATION',
              },
              {
                label: '传染病菌种、毒种扩散罪',
                value: '13845',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害国境卫生检疫罪',
                value: '13847',
                caseType: 'LITIGATION',
              },
              {
                label: '非法组织卖血罪',
                value: '13848',
                caseType: 'LITIGATION',
              },
              {
                label: '强迫卖血罪',
                value: '13849',
                caseType: 'LITIGATION',
              },
              {
                label: '非法采集、供应血液、制作、供应血液制品罪',
                value: '13850',
                caseType: 'LITIGATION',
              },
              {
                label: '采集、供应血液、制作、供应血液制品事故罪',
                value: '13851',
                caseType: 'LITIGATION',
              },
              {
                label: '医疗事故罪',
                value: '13852',
                caseType: 'LITIGATION',
              },
              {
                label: '非法行医罪',
                value: '13853',
                caseType: 'LITIGATION',
              },
              {
                label: '非法进行节育手术罪',
                value: '13854',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害动植物防疫、检疫罪',
                value: '13856',
                caseType: 'LITIGATION',
              },
              {
                label: '非法采集人类遗传资源、走私人类遗传资源材料罪',
                value: '13918',
                caseType: 'LITIGATION',
              },
              {
                label: '非法植入基因编辑、克隆胚胎罪',
                value: '13919',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '破坏环境资源保护罪',
            value: '13604',
            caseType: 'LITIGATION',
            children: [
              {
                label: '污染环境罪',
                value: '13858',
                caseType: 'LITIGATION',
              },
              {
                label: '非法处置进口的固体废物罪',
                value: '13859',
                caseType: 'LITIGATION',
              },
              {
                label: '擅自进口固体废物罪',
                value: '13860',
                caseType: 'LITIGATION',
              },
              {
                label: '非法捕捞水产品罪',
                value: '13861',
                caseType: 'LITIGATION',
              },
              {
                label: '危害珍贵、濒危野生动物罪',
                value: '13862',
                caseType: 'LITIGATION',
              },
              {
                label: '非法狩猎罪',
                value: '13864',
                caseType: 'LITIGATION',
              },
              {
                label: '非法占用农用地罪',
                value: '13866',
                caseType: 'LITIGATION',
              },
              {
                label: '非法采矿罪',
                value: '13867',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏性采矿罪',
                value: '13868',
                caseType: 'LITIGATION',
              },
              {
                label: '危害国家重点保护植物罪',
                value: '13869',
                caseType: 'LITIGATION',
              },
              {
                label: '盗伐林木罪',
                value: '13872',
                caseType: 'LITIGATION',
              },
              {
                label: '滥伐林木罪',
                value: '13873',
                caseType: 'LITIGATION',
              },
              {
                label: '非法收购、运输盗伐、滥伐的林木罪',
                value: '13874',
                caseType: 'LITIGATION',
              },
              {
                label: '非法猎捕、收购、运输、出售陆生野生动物罪',
                value: '13920',
                caseType: 'LITIGATION',
              },
              {
                label: '破坏自然保护地罪',
                value: '13921',
                caseType: 'LITIGATION',
              },
              {
                label: '非法引进、释放、丢弃外来入侵物种罪',
                value: '13923',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '走私、贩卖、运输、制造毒品罪',
            value: '13605',
            caseType: 'LITIGATION',
            children: [
              {
                label: '走私、贩卖、运输、制造毒品罪',
                value: '13876',
                caseType: 'LITIGATION',
              },
              {
                label: '非法持有毒品罪',
                value: '13877',
                caseType: 'LITIGATION',
              },
              {
                label: '包庇毒品犯罪分子罪',
                value: '13878',
                caseType: 'LITIGATION',
              },
              {
                label: '窝藏、转移、隐瞒毒品、毒赃罪',
                value: '13879',
                caseType: 'LITIGATION',
              },
              {
                label: '非法生产、买卖、运输制毒物品、走私制毒物品罪',
                value: '13882',
                caseType: 'LITIGATION',
              },
              {
                label: '非法种植毒品原植物罪',
                value: '13883',
                caseType: 'LITIGATION',
              },
              {
                label: '非法买卖、运输、携带、持有毒品原植物种子、幼苗罪',
                value: '13884',
                caseType: 'LITIGATION',
              },
              {
                label: '引诱、教唆、欺骗他人吸毒罪',
                value: '13885',
                caseType: 'LITIGATION',
              },
              {
                label: '强迫他人吸毒罪',
                value: '13886',
                caseType: 'LITIGATION',
              },
              {
                label: '容留他人吸毒罪',
                value: '13887',
                caseType: 'LITIGATION',
              },
              {
                label: '非法提供麻醉药品、精神药品罪',
                value: '13888',
                caseType: 'LITIGATION',
              },
              {
                label: '妨害兴奋剂管理罪',
                value: '13924',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '组织、强迫、引诱、容留、介绍卖淫罪',
            value: '13606',
            caseType: 'LITIGATION',
            children: [
              {
                label: '组织卖淫罪',
                value: '13889',
                caseType: 'LITIGATION',
              },
              {
                label: '强迫卖淫罪',
                value: '13890',
                caseType: 'LITIGATION',
              },
              {
                label: '协助组织卖淫罪',
                value: '13891',
                caseType: 'LITIGATION',
              },
              {
                label: '引诱、容留、介绍卖淫罪',
                value: '13892',
                caseType: 'LITIGATION',
              },
              {
                label: '引诱幼女卖淫罪',
                value: '13893',
                caseType: 'LITIGATION',
              },
              {
                label: '传播性病罪',
                value: '13894',
                caseType: 'LITIGATION',
              },
            ],
          },
          {
            label: '制作、贩卖、传播淫秽物品罪',
            value: '13607',
            caseType: 'LITIGATION',
            children: [
              {
                label: '制作、复制、出版、贩卖、传播淫秽物品牟利罪',
                value: '13896',
                caseType: 'LITIGATION',
              },
              {
                label: '为他人提供书号出版淫秽书刊罪',
                value: '13897',
                caseType: 'LITIGATION',
              },
              {
                label: '传播淫秽物品罪',
                value: '13898',
                caseType: 'LITIGATION',
              },
              {
                label: '组织播放淫秽音像制品罪',
                value: '13899',
                caseType: 'LITIGATION',
              },
              {
                label: '组织淫秽表演罪',
                value: '13900',
                caseType: 'LITIGATION',
              },
            ],
          },
        ],
      },
      {
        label: '危害国防利益罪',
        value: '11316',
        caseType: 'LITIGATION',
        children: [
          {
            label: '阻碍军人执行职务罪',
            value: '11317',
            caseType: 'LITIGATION',
          },
          {
            label: '阻碍军事行动罪',
            value: '11318',
            caseType: 'LITIGATION',
          },
          {
            label: '聚众冲击军事禁区罪',
            value: '11323',
            caseType: 'LITIGATION',
          },
          {
            label: '聚众扰乱军事管理区秩序罪',
            value: '11324',
            caseType: 'LITIGATION',
          },
          {
            label: '煽动军人逃离部队罪',
            value: '11325',
            caseType: 'LITIGATION',
          },
          {
            label: '雇用逃离部队军人罪',
            value: '11326',
            caseType: 'LITIGATION',
          },
          {
            label: '接送不合格兵员罪',
            value: '11327',
            caseType: 'LITIGATION',
          },
          {
            label: '战时故意提供虚假敌情罪',
            value: '11334',
            caseType: 'LITIGATION',
          },
          {
            label: '战时造谣扰乱军心罪',
            value: '11335',
            caseType: 'LITIGATION',
          },
          {
            label: '战时窝藏逃离部队军人罪',
            value: '11336',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏武器装备、军事设施、军事通信罪',
            value: '13609',
            caseType: 'LITIGATION',
          },
          {
            label: '过失损坏武器装备、军事设施、军事通信罪',
            value: '13610',
            caseType: 'LITIGATION',
          },
          {
            label: '故意提供不合格武器装备、军事设施罪',
            value: '13611',
            caseType: 'LITIGATION',
          },
          {
            label: '过失提供不合格武器装备、军事设施罪',
            value: '13612',
            caseType: 'LITIGATION',
          },
          {
            label: '冒充军人招摇撞骗罪',
            value: '13613',
            caseType: 'LITIGATION',
          },
          {
            label: '伪造、变造、买卖武装部队公文、证件、印章罪',
            value: '13614',
            caseType: 'LITIGATION',
          },
          {
            label: '盗窃、抢夺武装部队公文、证件、印章罪',
            value: '13615',
            caseType: 'LITIGATION',
          },
          {
            label: '非法生产、买卖武装部队制式服装罪',
            value: '13617',
            caseType: 'LITIGATION',
          },
          {
            label: '伪造、盗窃、买卖、非法提供、非法使用武装部队专用标志罪',
            value: '13618',
            caseType: 'LITIGATION',
          },
          {
            label: '战时拒绝、逃避征召、军事训练罪',
            value: '13619',
            caseType: 'LITIGATION',
          },
          {
            label: '战时拒绝、逃避服役罪',
            value: '13620',
            caseType: 'LITIGATION',
          },
          {
            label: '战时拒绝、故意延误军事订货罪',
            value: '13621',
            caseType: 'LITIGATION',
          },
          {
            label: '战时拒绝军事征收、征用罪',
            value: '13623',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '贪污贿赂罪',
        value: '11339',
        caseType: 'LITIGATION',
        children: [
          {
            label: '贪污罪',
            value: '11340',
            caseType: 'LITIGATION',
          },
          {
            label: '挪用公款罪',
            value: '11341',
            caseType: 'LITIGATION',
          },
          {
            label: '受贿罪',
            value: '11342',
            caseType: 'LITIGATION',
          },
          {
            label: '单位受贿罪',
            value: '11343',
            caseType: 'LITIGATION',
          },
          {
            label: '利用影响力受贿罪',
            value: '11344',
            caseType: 'LITIGATION',
          },
          {
            label: '行贿罪',
            value: '11345',
            caseType: 'LITIGATION',
          },
          {
            label: '对有影响力的人行贿罪',
            value: '11346',
            caseType: 'LITIGATION',
          },
          {
            label: '对单位行贿罪',
            value: '11347',
            caseType: 'LITIGATION',
          },
          {
            label: '介绍贿赂罪',
            value: '11348',
            caseType: 'LITIGATION',
          },
          {
            label: '单位行贿罪',
            value: '11349',
            caseType: 'LITIGATION',
          },
          {
            label: '巨额财产来源不明罪',
            value: '11350',
            caseType: 'LITIGATION',
          },
          {
            label: '隐瞒境外存款罪',
            value: '11351',
            caseType: 'LITIGATION',
          },
          {
            label: '私分国有资产罪',
            value: '11352',
            caseType: 'LITIGATION',
          },
          {
            label: '私分罚没财物罪',
            value: '11353',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '渎职罪',
        value: '11354',
        caseType: 'LITIGATION',
        children: [
          {
            label: '滥用职权罪',
            value: '11355',
            caseType: 'LITIGATION',
          },
          {
            label: '故意泄露国家秘密罪',
            value: '11356',
            caseType: 'LITIGATION',
          },
          {
            label: '徇私枉法罪',
            value: '11357',
            caseType: 'LITIGATION',
          },
          {
            label: '枉法仲裁罪',
            value: '11361',
            caseType: 'LITIGATION',
          },
          {
            label: '私放在押人员罪',
            value: '11362',
            caseType: 'LITIGATION',
          },
          {
            label: '失职致使在押人员脱逃罪',
            value: '11363',
            caseType: 'LITIGATION',
          },
          {
            label: '徇私舞弊不移交刑事案件罪',
            value: '11365',
            caseType: 'LITIGATION',
          },
          {
            label: '违法发放林木采伐许可证罪',
            value: '11371',
            caseType: 'LITIGATION',
          },
          {
            label: '环境监管失职罪',
            value: '11372',
            caseType: 'LITIGATION',
          },
          {
            label: '食品、药品监管渎职罪',
            value: '11373',
            caseType: 'LITIGATION',
          },
          {
            label: '传染病防治失职罪',
            value: '11374',
            caseType: 'LITIGATION',
          },
          {
            label: '非法低价出让国有土地使用权罪',
            value: '11376',
            caseType: 'LITIGATION',
          },
          {
            label: '放纵走私罪',
            value: '11377',
            caseType: 'LITIGATION',
          },
          {
            label: '商检徇私舞弊罪',
            value: '11378',
            caseType: 'LITIGATION',
          },
          {
            label: '商检失职罪',
            value: '11379',
            caseType: 'LITIGATION',
          },
          {
            label: '动植物检疫徇私舞弊罪',
            value: '11380',
            caseType: 'LITIGATION',
          },
          {
            label: '动植物检疫失职罪',
            value: '11381',
            caseType: 'LITIGATION',
          },
          {
            label: '放纵制售伪劣商品犯罪行为罪',
            value: '11382',
            caseType: 'LITIGATION',
          },
          {
            label: '帮助犯罪分子逃避处罚罪',
            value: '11387',
            caseType: 'LITIGATION',
          },
          {
            label: '玩忽职守罪',
            value: '13624',
            caseType: 'LITIGATION',
          },
          {
            label: '过失泄露国家秘密罪',
            value: '13625',
            caseType: 'LITIGATION',
          },
          {
            label: '民事、行政枉法裁判罪',
            value: '13627',
            caseType: 'LITIGATION',
          },
          {
            label: '执行判决、裁定失职罪',
            value: '13628',
            caseType: 'LITIGATION',
          },
          {
            label: '执行判决、裁定滥用职权罪',
            value: '13629',
            caseType: 'LITIGATION',
          },
          {
            label: '徇私舞弊减刑、假释、暂予监外执行罪',
            value: '13630',
            caseType: 'LITIGATION',
          },
          {
            label: '滥用管理公司、证券职权罪',
            value: '13631',
            caseType: 'LITIGATION',
          },
          {
            label: '徇私舞弊不征、少征税款罪',
            value: '13632',
            caseType: 'LITIGATION',
          },
          {
            label: '徇私舞弊发售发票、抵扣税款、出口退税罪',
            value: '13633',
            caseType: 'LITIGATION',
          },
          {
            label: '违法提供出口退税凭证罪',
            value: '13634',
            caseType: 'LITIGATION',
          },
          {
            label: '国家机关工作人员签订、履行合同失职被骗罪',
            value: '13636',
            caseType: 'LITIGATION',
          },
          {
            label: '非法批准征收、征用、占用土地罪',
            value: '13638',
            caseType: 'LITIGATION',
          },
          {
            label: '办理偷越国（边）境人员出入境证件罪',
            value: '13925',
            caseType: 'LITIGATION',
          },
          {
            label: '放行偷越国（边）境人员罪',
            value: '13926',
            caseType: 'LITIGATION',
          },
          {
            label: '不解救被拐卖、绑架妇女、儿童罪',
            value: '13641',
            caseType: 'LITIGATION',
          },
          {
            label: '阻碍解救被拐卖、绑架妇女、儿童罪',
            value: '13642',
            caseType: 'LITIGATION',
          },
          {
            label: '招收公务员、学生徇私舞弊罪',
            value: '13644',
            caseType: 'LITIGATION',
          },
          {
            label: '失职造成珍贵文物损毁、流失罪',
            value: '13645',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '军人违反职责罪',
        value: '11390',
        caseType: 'LITIGATION',
        children: [
          {
            label: '战时违抗命令罪',
            value: '11391',
            caseType: 'LITIGATION',
          },
          {
            label: '投降罪',
            value: '11394',
            caseType: 'LITIGATION',
          },
          {
            label: '战时临阵脱逃罪',
            value: '11395',
            caseType: 'LITIGATION',
          },
          {
            label: '阻碍执行军事职务罪',
            value: '11397',
            caseType: 'LITIGATION',
          },
          {
            label: '指使部属违反职责罪',
            value: '11398',
            caseType: 'LITIGATION',
          },
          {
            label: '违令作战消极罪',
            value: '11399',
            caseType: 'LITIGATION',
          },
          {
            label: '拒不救援友邻部队罪',
            value: '11400',
            caseType: 'LITIGATION',
          },
          {
            label: '军人叛逃罪',
            value: '11401',
            caseType: 'LITIGATION',
          },
          {
            label: '非法获取军事秘密罪',
            value: '11402',
            caseType: 'LITIGATION',
          },
          {
            label: '故意泄露军事秘密罪',
            value: '11404',
            caseType: 'LITIGATION',
          },
          {
            label: '过失泄露军事秘密罪',
            value: '11405',
            caseType: 'LITIGATION',
          },
          {
            label: '战时造谣惑众罪',
            value: '11406',
            caseType: 'LITIGATION',
          },
          {
            label: '战时自伤罪',
            value: '11407',
            caseType: 'LITIGATION',
          },
          {
            label: '逃离部队罪',
            value: '11408',
            caseType: 'LITIGATION',
          },
          {
            label: '武器装备肇事罪',
            value: '11409',
            caseType: 'LITIGATION',
          },
          {
            label: '擅自改变武器装备编配用途罪',
            value: '11410',
            caseType: 'LITIGATION',
          },
          {
            label: '遗弃武器装备罪',
            value: '11413',
            caseType: 'LITIGATION',
          },
          {
            label: '遗失武器装备罪',
            value: '11414',
            caseType: 'LITIGATION',
          },
          {
            label: '虐待部属罪',
            value: '11416',
            caseType: 'LITIGATION',
          },
          {
            label: '遗弃伤病军人罪',
            value: '11417',
            caseType: 'LITIGATION',
          },
          {
            label: '战时拒不救治伤病军人罪',
            value: '11418',
            caseType: 'LITIGATION',
          },
          {
            label: '私放俘虏罪',
            value: '11420',
            caseType: 'LITIGATION',
          },
          {
            label: '虐待俘虏罪',
            value: '11421',
            caseType: 'LITIGATION',
          },
          {
            label: '战时造谣惑众罪',
            value: '11422',
            caseType: 'LITIGATION',
          },
          {
            label: '隐瞒、谎报军情罪',
            value: '13646',
            caseType: 'LITIGATION',
          },
          {
            label: '拒传、假传军令罪',
            value: '13647',
            caseType: 'LITIGATION',
          },
          {
            label: '擅离、玩忽军事职守罪',
            value: '13648',
            caseType: 'LITIGATION',
          },
          {
            label: '盗窃、抢夺武器装备、军用物资罪',
            value: '13651',
            caseType: 'LITIGATION',
          },
          {
            label: '非法出卖、转让武器装备罪',
            value: '13652',
            caseType: 'LITIGATION',
          },
          {
            label: '擅自出卖、转让军队房地产罪',
            value: '13653',
            caseType: 'LITIGATION',
          },
          {
            label: '战时残害居民、掠夺居民财物罪',
            value: '13654',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '侵犯公民人身权利、民主权利罪',
        value: '13517',
        caseType: 'LITIGATION',
        children: [
          {
            label: '故意杀人罪',
            value: '13550',
            caseType: 'LITIGATION',
          },
          {
            label: '过失致人死亡罪',
            value: '13551',
            caseType: 'LITIGATION',
          },
          {
            label: '故意伤害罪',
            value: '13552',
            caseType: 'LITIGATION',
          },
          {
            label: '组织出卖人体器官罪',
            value: '13553',
            caseType: 'LITIGATION',
          },
          {
            label: '过失致人重伤罪',
            value: '13554',
            caseType: 'LITIGATION',
          },
          {
            label: '强奸罪',
            value: '13555',
            caseType: 'LITIGATION',
          },
          {
            label: '强制猥亵、侮辱罪',
            value: '13557',
            caseType: 'LITIGATION',
          },
          {
            label: '猥亵儿童罪',
            value: '13559',
            caseType: 'LITIGATION',
          },
          {
            label: '非法拘禁罪',
            value: '13560',
            caseType: 'LITIGATION',
          },
          {
            label: '绑架罪',
            value: '13561',
            caseType: 'LITIGATION',
          },
          {
            label: '拐卖妇女、儿童罪',
            value: '13562',
            caseType: 'LITIGATION',
          },
          {
            label: '收买被拐卖的妇女、儿童罪',
            value: '13563',
            caseType: 'LITIGATION',
          },
          {
            label: '聚众阻碍解救被收买的妇女、儿童罪',
            value: '13564',
            caseType: 'LITIGATION',
          },
          {
            label: '诬告陷害罪',
            value: '13565',
            caseType: 'LITIGATION',
          },
          {
            label: '强迫劳动罪',
            value: '13567',
            caseType: 'LITIGATION',
          },
          {
            label: '雇用童工从事危重劳动罪',
            value: '13568',
            caseType: 'LITIGATION',
          },
          {
            label: '非法搜查罪',
            value: '13569',
            caseType: 'LITIGATION',
          },
          {
            label: '非法侵入住宅罪',
            value: '13570',
            caseType: 'LITIGATION',
          },
          {
            label: '侮辱罪',
            value: '13571',
            caseType: 'LITIGATION',
          },
          {
            label: '诽谤罪',
            value: '13572',
            caseType: 'LITIGATION',
          },
          {
            label: '刑讯逼供罪',
            value: '13573',
            caseType: 'LITIGATION',
          },
          {
            label: '暴力取证罪',
            value: '13574',
            caseType: 'LITIGATION',
          },
          {
            label: '虐待被监管人罪',
            value: '13575',
            caseType: 'LITIGATION',
          },
          {
            label: '煽动民族仇恨、民族歧视罪',
            value: '13576',
            caseType: 'LITIGATION',
          },
          {
            label: '出版歧视、侮辱少数民族作品罪',
            value: '13577',
            caseType: 'LITIGATION',
          },
          {
            label: '非法剥夺公民宗教信仰自由罪',
            value: '13578',
            caseType: 'LITIGATION',
          },
          {
            label: '侵犯少数民族风俗习惯罪',
            value: '13579',
            caseType: 'LITIGATION',
          },
          {
            label: '侵犯通信自由罪',
            value: '13580',
            caseType: 'LITIGATION',
          },
          {
            label: '私自开拆、隐匿、毁弃邮件、电报罪',
            value: '13581',
            caseType: 'LITIGATION',
          },
          {
            label: '侵犯公民个人信息罪',
            value: '13584',
            caseType: 'LITIGATION',
          },
          {
            label: '报复陷害罪',
            value: '13585',
            caseType: 'LITIGATION',
          },
          {
            label: '打击报复会计、统计人员罪',
            value: '13586',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏选举罪',
            value: '13587',
            caseType: 'LITIGATION',
          },
          {
            label: '暴力干涉婚姻自由罪',
            value: '13588',
            caseType: 'LITIGATION',
          },
          {
            label: '重婚罪',
            value: '13589',
            caseType: 'LITIGATION',
          },
          {
            label: '破坏军婚罪',
            value: '13590',
            caseType: 'LITIGATION',
          },
          {
            label: '虐待罪',
            value: '13591',
            caseType: 'LITIGATION',
          },
          {
            label: '虐待被监护、看护人罪',
            value: '13592',
            caseType: 'LITIGATION',
          },
          {
            label: '遗弃罪',
            value: '13593',
            caseType: 'LITIGATION',
          },
          {
            label: '拐骗儿童罪',
            value: '13594',
            caseType: 'LITIGATION',
          },
          {
            label: '组织残疾人、儿童乞讨罪',
            value: '13595',
            caseType: 'LITIGATION',
          },
          {
            label: '组织未成年人进行违反治安管理活动罪',
            value: '13596',
            caseType: 'LITIGATION',
          },
          {
            label: '负有照护职责人员性侵罪',
            value: '13911',
            caseType: 'LITIGATION',
          },
        ],
      },
    ],
  },
  {
    label: '执行',
    value: '11423',
    caseType: 'LITIGATION',
    children: [
      {
        label: '民商事执行案件',
        value: '11424',
        caseType: 'LITIGATION',
      },
      {
        label: '刑事执行案件',
        value: '11425',
        caseType: 'LITIGATION',
      },
      {
        label: '行政诉讼执行案件',
        value: '11426',
        caseType: 'LITIGATION',
      },
      {
        label: '非诉行政行为执行案件',
        value: '11427',
        caseType: 'LITIGATION',
      },
      {
        label: '仲裁执行案件',
        value: '11428',
        caseType: 'LITIGATION',
      },
      {
        label: '公证债权文书执行案件',
        value: '11429',
        caseType: 'LITIGATION',
      },
    ],
  },
  {
    label: '行政',
    value: '11430',
    caseType: 'LITIGATION',
    children: [
      {
        label: '行政裁决',
        value: '11500',
        caseType: 'LITIGATION',
        children: [
          {
            label: '土地、矿藏、水流、荒地或者滩涂权属确权',
            value: '13493',
            caseType: 'LITIGATION',
          },
          {
            label: '林地、林木、山岭权属确权',
            value: '13494',
            caseType: 'LITIGATION',
          },
          {
            label: '海域使用权确权',
            value: '13495',
            caseType: 'LITIGATION',
          },
          {
            label: '草原权属确权',
            value: '13496',
            caseType: 'LITIGATION',
          },
          {
            label: '水利工程权属确权',
            value: '13497',
            caseType: 'LITIGATION',
          },
          {
            label: '企业资产性质确认',
            value: '13498',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政确认',
        value: '11501',
        caseType: 'LITIGATION',
        children: [
          {
            label: '基本养老保险资格或者待遇认定',
            value: '13442',
            caseType: 'LITIGATION',
          },
          {
            label: '基本医疗保险资格或者待遇认定',
            value: '13443',
            caseType: 'LITIGATION',
          },
          {
            label: '失业保险资格或者待遇认定',
            value: '13444',
            caseType: 'LITIGATION',
          },
          {
            label: '工伤保险资格或者待遇认定',
            value: '13445',
            caseType: 'LITIGATION',
          },
          {
            label: '生育保险资格或者待遇认定',
            value: '13446',
            caseType: 'LITIGATION',
          },
          {
            label: '最低生活保障资格或者待遇认定',
            value: '13447',
            caseType: 'LITIGATION',
          },
          {
            label: '确认保障性住房分配资格',
            value: '13448',
            caseType: 'LITIGATION',
          },
          {
            label: '颁发学位证书或者毕业证书',
            value: '13449',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政登记',
        value: '11502',
        caseType: 'LITIGATION',
        children: [
          {
            label: '房屋所有权登记',
            value: '13422',
            caseType: 'LITIGATION',
          },
          {
            label: '集体土地所有权登记',
            value: '13423',
            caseType: 'LITIGATION',
          },
          {
            label: '森林、林木所有权登记',
            value: '13424',
            caseType: 'LITIGATION',
          },
          {
            label: '矿业权登记',
            value: '13425',
            caseType: 'LITIGATION',
          },
          {
            label: '土地承包经营权登记',
            value: '13426',
            caseType: 'LITIGATION',
          },
          {
            label: '建设用地使用权登记',
            value: '13427',
            caseType: 'LITIGATION',
          },
          {
            label: '宅基地使用权登记',
            value: '13428',
            caseType: 'LITIGATION',
          },
          {
            label: '海域使用权登记',
            value: '13429',
            caseType: 'LITIGATION',
          },
          {
            label: '水利工程登记',
            value: '13430',
            caseType: 'LITIGATION',
          },
          {
            label: '居住权登记',
            value: '13431',
            caseType: 'LITIGATION',
          },
          {
            label: '地役权登记',
            value: '13432',
            caseType: 'LITIGATION',
          },
          {
            label: '不动产抵押登记',
            value: '13433',
            caseType: 'LITIGATION',
          },
          {
            label: '动产抵押登记',
            value: '13434',
            caseType: 'LITIGATION',
          },
          {
            label: '质押登记',
            value: '13435',
            caseType: 'LITIGATION',
          },
          {
            label: '机动车所有权登记',
            value: '13436',
            caseType: 'LITIGATION',
          },
          {
            label: '船舶所有权登记',
            value: '13437',
            caseType: 'LITIGATION',
          },
          {
            label: '户籍登记',
            value: '13438',
            caseType: 'LITIGATION',
          },
          {
            label: '婚姻登记',
            value: '13439',
            caseType: 'LITIGATION',
          },
          {
            label: '收养登记',
            value: '13440',
            caseType: 'LITIGATION',
          },
          {
            label: '税务登记',
            value: '13441',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政许可',
        value: '11503',
        caseType: 'LITIGATION',
        children: [
          {
            label: '工商登记',
            value: '13409',
            caseType: 'LITIGATION',
          },
          {
            label: '社会团体登记',
            value: '13410',
            caseType: 'LITIGATION',
          },
          {
            label: '颁发机动车驾驶证',
            value: '13411',
            caseType: 'LITIGATION',
          },
          {
            label: '特许经营许可',
            value: '13412',
            caseType: 'LITIGATION',
          },
          {
            label: '建设工程规划许可',
            value: '13413',
            caseType: 'LITIGATION',
          },
          {
            label: '建筑工程施工许可',
            value: '13414',
            caseType: 'LITIGATION',
          },
          {
            label: '矿产资源许可',
            value: '13415',
            caseType: 'LITIGATION',
          },
          {
            label: '药品注册许可',
            value: '13416',
            caseType: 'LITIGATION',
          },
          {
            label: '医疗器械许可',
            value: '13417',
            caseType: 'LITIGATION',
          },
          {
            label: '执业资格许可',
            value: '13418',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政复议',
        value: '11506',
        caseType: 'LITIGATION',
        children: [
          {
            label: '不予受理行政复议申请决定',
            value: '13489',
            caseType: 'LITIGATION',
          },
          {
            label: '驳回行政复议申请决定',
            value: '13490',
            caseType: 'LITIGATION',
          },
          {
            label: 'XX（行政行为）及行政复议',
            value: '13491',
            caseType: 'LITIGATION',
          },
          {
            label: '改变原行政行为的行政复议决定',
            value: '13492',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政奖励',
        value: '11510',
        caseType: 'LITIGATION',
        children: [
          {
            label: '授予荣誉称号',
            value: '13468',
            caseType: 'LITIGATION',
          },
          {
            label: '发放奖金',
            value: '13469',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政补偿',
        value: '11511',
        caseType: 'LITIGATION',
        children: [
          {
            label: '房屋征收或者征用补偿',
            value: '13510',
            caseType: 'LITIGATION',
          },
          {
            label: '土地征收或者征用补偿',
            value: '13511',
            caseType: 'LITIGATION',
          },
          {
            label: '动产征收或者征用补偿',
            value: '13512',
            caseType: 'LITIGATION',
          },
          {
            label: '撤回行政许可补偿',
            value: '13513',
            caseType: 'LITIGATION',
          },
          {
            label: '收回国有土地使用权补偿',
            value: '13514',
            caseType: 'LITIGATION',
          },
          {
            label: '规划变更补偿',
            value: '13515',
            caseType: 'LITIGATION',
          },
          {
            label: '移民安置补偿',
            value: '13516',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政给付',
        value: '11514',
        caseType: 'LITIGATION',
        children: [
          {
            label: '给付抚恤金',
            value: '13450',
            caseType: 'LITIGATION',
          },
          {
            label: '给付基本养老金',
            value: '13451',
            caseType: 'LITIGATION',
          },
          {
            label: '给付基本医疗保险金',
            value: '13452',
            caseType: 'LITIGATION',
          },
          {
            label: '给付失业保险金',
            value: '13453',
            caseType: 'LITIGATION',
          },
          {
            label: '给付工伤保险金',
            value: '13454',
            caseType: 'LITIGATION',
          },
          {
            label: '给付生育保险金',
            value: '13455',
            caseType: 'LITIGATION',
          },
          {
            label: '给付最低生活保障金',
            value: '13456',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政允诺',
        value: '11522',
        caseType: 'LITIGATION',
        children: [
          {
            label: '兑现奖金',
            value: '13457',
            caseType: 'LITIGATION',
          },
          {
            label: '兑现优惠',
            value: '13458',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政处罚',
        value: '11562',
        caseType: 'LITIGATION',
        children: [
          {
            label: '警告',
            value: '13377',
            caseType: 'LITIGATION',
          },
          {
            label: '通报批评',
            value: '13378',
            caseType: 'LITIGATION',
          },
          {
            label: '罚款',
            value: '13379',
            caseType: 'LITIGATION',
          },
          {
            label: '没收违法所得',
            value: '13380',
            caseType: 'LITIGATION',
          },
          {
            label: '没收非法财物',
            value: '13381',
            caseType: 'LITIGATION',
          },
          {
            label: '暂扣许可证件',
            value: '13382',
            caseType: 'LITIGATION',
          },
          {
            label: '吊销许可证件',
            value: '13383',
            caseType: 'LITIGATION',
          },
          {
            label: '降低资质等级',
            value: '13384',
            caseType: 'LITIGATION',
          },
          {
            label: '责令关闭',
            value: '13385',
            caseType: 'LITIGATION',
          },
          {
            label: '责令停产停业',
            value: '13386',
            caseType: 'LITIGATION',
          },
          {
            label: '限制开展生产经营活动',
            value: '13387',
            caseType: 'LITIGATION',
          },
          {
            label: '限制从业',
            value: '13388',
            caseType: 'LITIGATION',
          },
          {
            label: '行政拘留',
            value: '13389',
            caseType: 'LITIGATION',
          },
          {
            label: '不得申请行政许可',
            value: '13390',
            caseType: 'LITIGATION',
          },
          {
            label: '责令限期拆除',
            value: '13391',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政强制措施',
        value: '13365',
        caseType: 'LITIGATION',
        children: [
          {
            label: '限制人身自由',
            value: '13392',
            caseType: 'LITIGATION',
          },
          {
            label: '查封场所、设施或者财物',
            value: '13393',
            caseType: 'LITIGATION',
          },
          {
            label: '扣押财物',
            value: '13394',
            caseType: 'LITIGATION',
          },
          {
            label: '冻结存款、汇款',
            value: '13395',
            caseType: 'LITIGATION',
          },
          {
            label: '冻结资金、证券',
            value: '13396',
            caseType: 'LITIGATION',
          },
          {
            label: '强制隔离戒毒',
            value: '13397',
            caseType: 'LITIGATION',
          },
          {
            label: '留置',
            value: '13398',
            caseType: 'LITIGATION',
          },
          {
            label: '采取保护性约束措施',
            value: '13399',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政强制执行',
        value: '13366',
        caseType: 'LITIGATION',
        children: [
          {
            label: '加处罚款或者滞纳金',
            value: '13400',
            caseType: 'LITIGATION',
          },
          {
            label: '划拨存款、汇款',
            value: '13401',
            caseType: 'LITIGATION',
          },
          {
            label: '拍卖查封、扣押的场所、设施或者财物',
            value: '13402',
            caseType: 'LITIGATION',
          },
          {
            label: '处理查封、扣押的场所、设施或者财物',
            value: '13403',
            caseType: 'LITIGATION',
          },
          {
            label: '排除妨碍',
            value: '13404',
            caseType: 'LITIGATION',
          },
          {
            label: '恢复原状',
            value: '13405',
            caseType: 'LITIGATION',
          },
          {
            label: '代履行',
            value: '13406',
            caseType: 'LITIGATION',
          },
          {
            label: '强制拆除房屋或者设施',
            value: '13407',
            caseType: 'LITIGATION',
          },
          {
            label: '强制清除地上物',
            value: '13408',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政征收或者征用',
        value: '13367',
        caseType: 'LITIGATION',
        children: [
          {
            label: '征收或者征用房屋',
            value: '13419',
            caseType: 'LITIGATION',
          },
          {
            label: '征收或者征用土地',
            value: '13420',
            caseType: 'LITIGATION',
          },
          {
            label: '征收或者征用动产',
            value: '13421',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政征缴',
        value: '13368',
        caseType: 'LITIGATION',
        children: [
          {
            label: '征缴税款',
            value: '13459',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴社会抚养费',
            value: '13460',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴社会保险费',
            value: '13461',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴污水处理费',
            value: '13462',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴防空地下室易地建设费',
            value: '13463',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴水土保持补偿费',
            value: '13464',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴土地闲置费',
            value: '13465',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴土地复垦费',
            value: '13466',
            caseType: 'LITIGATION',
          },
          {
            label: '征缴耕地开垦费',
            value: '13467',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政收费',
        value: '13369',
        caseType: 'LITIGATION',
        children: [
          {
            label: '证照费',
            value: '13470',
            caseType: 'LITIGATION',
          },
          {
            label: '车辆通行费',
            value: '13471',
            caseType: 'LITIGATION',
          },
          {
            label: '企业注册登记费',
            value: '13472',
            caseType: 'LITIGATION',
          },
          {
            label: '不动产登记费',
            value: '13473',
            caseType: 'LITIGATION',
          },
          {
            label: '船舶登记费',
            value: '13474',
            caseType: 'LITIGATION',
          },
          {
            label: '考试考务费',
            value: '13475',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '政府信息公开',
        value: '13370',
        caseType: 'LITIGATION',
      },
      {
        label: '行政批复',
        value: '13371',
        caseType: 'LITIGATION',
      },
      {
        label: '行政处理',
        value: '13372',
        caseType: 'LITIGATION',
        children: [
          {
            label: '责令退还非法占用土地',
            value: '13476',
            caseType: 'LITIGATION',
          },
          {
            label: '责令交还土地',
            value: '13477',
            caseType: 'LITIGATION',
          },
          {
            label: '责令改正',
            value: '13478',
            caseType: 'LITIGATION',
          },
          {
            label: '责令采取补救措施',
            value: '13479',
            caseType: 'LITIGATION',
          },
          {
            label: '责令停止建设',
            value: '13480',
            caseType: 'LITIGATION',
          },
          {
            label: '责令恢复原状',
            value: '13481',
            caseType: 'LITIGATION',
          },
          {
            label: '责令公开',
            value: '13482',
            caseType: 'LITIGATION',
          },
          {
            label: '责令召回',
            value: '13483',
            caseType: 'LITIGATION',
          },
          {
            label: '责令暂停生产',
            value: '13484',
            caseType: 'LITIGATION',
          },
          {
            label: '责令暂停销售',
            value: '13485',
            caseType: 'LITIGATION',
          },
          {
            label: '责令暂停使用',
            value: '13486',
            caseType: 'LITIGATION',
          },
          {
            label: '有偿收回国有土地使用权',
            value: '13487',
            caseType: 'LITIGATION',
          },
          {
            label: '退学决定',
            value: '13488',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政协议',
        value: '13373',
        caseType: 'LITIGATION',
        children: [
          {
            label: '订立XX（行政协议）',
            value: '13499',
            caseType: 'LITIGATION',
          },
          {
            label: '单方变更XX（行政协议）',
            value: '13500',
            caseType: 'LITIGATION',
          },
          {
            label: '单方解除XX（行政协议）',
            value: '13501',
            caseType: 'LITIGATION',
          },
          {
            label: '不依法履行XX（行政协议）',
            value: '13502',
            caseType: 'LITIGATION',
          },
          {
            label: '未按约定履行XX（行政协议）',
            value: '13503',
            caseType: 'LITIGATION',
          },
          {
            label: 'XX（行政协议）行政补偿',
            value: '13504',
            caseType: 'LITIGATION',
          },
          {
            label: 'XX（行政协议）行政赔偿',
            value: '13505',
            caseType: 'LITIGATION',
          },
          {
            label: '撤销XX（行政协议）',
            value: '13506',
            caseType: 'LITIGATION',
          },
          {
            label: '解除XX（行政协议）',
            value: '13507',
            caseType: 'LITIGATION',
          },
          {
            label: '继续履行XX（行政协议）',
            value: '13508',
            caseType: 'LITIGATION',
          },
          {
            label: '确认XX（行政协议）无效或有效',
            value: '13509',
            caseType: 'LITIGATION',
          },
        ],
      },
      {
        label: '行政赔偿',
        value: '13374',
        caseType: 'LITIGATION',
      },
      {
        label: '不履行XX职责',
        value: '13375',
        caseType: 'LITIGATION',
      },
      {
        label: 'XX（行政行为）公益诉讼',
        value: '13376',
        caseType: 'LITIGATION',
      },
    ],
  },
  {
    label: '交通事故',
    value: '11525',
    caseType: 'NON_LITIGATION',
    children: [
      {
        label: '交通事故纠纷',
        value: '11526',
        caseType: 'NON_LITIGATION',
      },
    ],
  },
  {
    label: '劳动争议',
    value: '11528',
    caseType: 'NON_LITIGATION',
    children: [
      {
        label: '劳动争议',
        value: '11529',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '劳动争议',
            value: '11530',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '确认劳动关系争议',
        value: '11531',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '确认劳动关系争议',
            value: '11532',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '劳动合同争议',
        value: '11533',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '订立劳动合同争议',
            value: '11534',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '履行劳动合同争议',
            value: '11535',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '变更劳动合同争议',
            value: '11536',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '解除劳动合同争议',
            value: '11537',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '终止劳动合同争议',
            value: '11538',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '经济补偿金、赔偿金争议',
            value: '11539',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '违约金争议',
            value: '11540',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '职业培训争议',
            value: '11541',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '社会保险争议',
        value: '11542',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '养老保险争议',
            value: '11543',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '医疗保险争议',
            value: '11544',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '工伤保险争议',
            value: '11545',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '失业保险争议',
            value: '11546',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '生育保险争议',
            value: '11547',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '社会保险争议',
            value: '11548',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '劳动报酬争议',
        value: '11549',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '追索劳动报酬争议',
            value: '11550',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '加班工资争议',
            value: '11551',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '工资标准争议',
            value: '11552',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '奖金、津贴、补贴争议',
            value: '11553',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '劳动报酬争议',
            value: '11554',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '福利待遇争议',
        value: '11555',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '福利待遇争议',
            value: '11556',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
      {
        label: '劳动保护争议',
        value: '11557',
        caseType: 'NON_LITIGATION',
        children: [
          {
            label: '女工保护',
            value: '11558',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '未成年工保护',
            value: '11559',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '工时、休假保护',
            value: '11560',
            caseType: 'NON_LITIGATION',
          },
          {
            label: '劳动保护争议',
            value: '11561',
            caseType: 'NON_LITIGATION',
          },
        ],
      },
    ],
  },
  {
    label: '仲裁纠纷',
    value: '11828',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '劳动仲裁',
    value: '13246',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '行政复议',
    value: '13247',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '商业仲裁',
    value: '13248',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '法律顾问',
    value: '13275',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '咨询代书',
    value: '13276',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '法律援助',
    value: '13277',
    caseType: 'NON_LITIGATION',
  },
  {
    label: '其他非诉',
    value: '13279',
    caseType: 'NON_LITIGATION',
  },
]
