<template>
  <div class="reason-item">
    <van-cell>
      <template slot="title">
        <div class="title-with-switch">
          <span class="select-reason" :style="selectReasonIconStyle" @click="selectReason"
            ><i class="iconfont icon-menu select-reason-icon"></i>
            <span style="color: red">*</span>
            <span class="text">案由</span></span
          >
          <input v-model="name" id="reason-input" placeholder="请选择或填写案由" class="name-input" @focus="onFocus" @blur="onBlur" />
          <i style="color: #c8c9cc" class="iconfont icon-clear-2 name-input-clear" v-show="name" @click="clearName"></i>
        </div>
      </template>
    </van-cell>
    <div class="search-suggestion" v-show="showSuggestion">
      <van-loading v-show="searching" />
      <div class="suggestion-list-item" v-for="p in suggestionList" :key="p.caseReasonId" @click.stop="select(p)">
        <div class="name">{{ p.name }}</div>
        <div class="attr">民事</div>
      </div>
    </div>
    <div class="reason-picker" v-show="picking">
      <van-tree-select :items="reasons" :main-active-index="mainActiveIndex" :active-id="activeId" @click-nav="onNavClick" style="height: 100%">
        <template #content>
          <template v-if="reasons && reasons.length > 0 && reasons[0] && reasons[0].children">
            <div v-for="item in reasons[0].children" :key="item.id" class="select__item">
              <span @click="onItemClick(item)">{{ item.text }}</span>
              <i @click="onItemArrowClick(item)" class="van-icon van-icon-arrow"></i>
            </div>
          </template>
        </template>
      </van-tree-select>
    </div>
    <van-popup v-model="showReasonTip" position="top" class="no-reason-tip" :overlay="false"> <van-icon name="fail" /> 没有上一级案由了 </van-popup>
    <div class="confirm-btn" v-if="showConfirmBtn">
      <van-button type="info" @click.stop="confirm(true)">保存并更新案件信息</van-button>
      <van-button plain hairline type="info" @click.stop="confirm(false)">直接保存</van-button>
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import { debounce } from 'throttle-debounce'
import { mapMutations } from 'vuex'

import ReasonData from '@common/reasonData'

export default {
  name: 'reason-picker',
  props: {
    reasonSuggestion: Array,
    flag: Number,
    showConfirmBtn: Boolean,
    reasonText: String,
  },
  components: {},
  data() {
    return {
      // 是否在编辑中
      editing: false,
      // 是否正在搜索
      searching: false,
      // 是否在选择案由
      picking: false,
      // 是否显示搜索建议
      showSuggestion: false,
      // 搜索建议列表数据
      suggestionList: [],
      name: '',
      // 显示案由提示
      showReasonTip: false,
      reason: null,
      // 左侧高亮元素的index
      mainActiveIndex: 0,
      // 被选中元素的id
      activeId: 1001,
      // 是否是最后一层
      isEnd: false,
      // 父级案由 记录两层以实现倒退
      // 暂时只显示民事，其他等轴详情页面处理好改为-1.
      parentReason: {
        text: '选择案由',
        id: '-1',
        parent: null,
      },
      reasons: [],
      selectedReasonObj: null,
      lastName: '',
    }
  },
  computed: {
    selectReasonIconStyle() {
      if (this.picking) {
        return {
          color: '#569af9',
        }
      } else {
        return ''
      }
    },
    canConfirm() {
      if (this.name) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapMutations('createCase', ['setReason']),
    // 通过reasonText查找案由对象
    recursionName(data, label) {
      let result
      if (!data) {
        return
      }
      for (var i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.label === label) {
          result = item
          break
        } else if (item.children && item.children.length > 0) {
          result = this.recursionName(item.children, label)
          if (result) return result // 关键代码：如果找到，就直接返回，结束后面的循环
        }
      }
      return result
    },
    search(name) {
      // 不能为空
      if (!name) {
        return false
      }
      // 检索前先清空上一次的数据
      this.suggestionList = []
      this.searching = true

      this.$axios
        .get(`${caseBase}/lts/commonCaseReason/vagueQueryCaseReason?name=${name}`)
        .then((res) => {
          this.searching = false
          if (res.data.code === '200') {
            this.suggestionList = res.data.data
          } else {
            this.suggestionList = []
          }
        })
        .catch((err) => {
          this.searching = false
          console.log(err)
        })
    },
    onFocus() {
      if (this.reasonText) {
        console.log('onFocus:', this.reasonText)
        this.name = this.reasonText
      }
      this.showSuggestion = true
    },
    onBlur() {
      setTimeout(() => {
        this.showSuggestion = false
      }, 400)
    },
    async select(p, cid) {
      let reasonId = ''
      if (p.name === p.caseReasonId) {
        const res = await this.$axios.get(`${caseBase}/lts/commonCaseReason/vagueQueryCaseReason?name=${p.name}`)
        if (res.data.code === '200' && res.data.data) {
          reasonId = res.data.data[0].caseReasonId
        }
      }
      if (p.name === p.caseReasonId) {
        p.caseReasonId = reasonId
      }
      this.reason = p
      // console.log(this.reason, 'reason')
      const { name: text, caseReasonId: id } = p
      const r = {
        text,
        id,
        caseReasonType: p.caseReasonType,
      }
      this.selectedReasonObj = r
      this.setReason(r)
      this.name = p.name
      this.showSuggestion = false
    },
    // 清除输入
    clearName() {
      this.name = ''
    },
    clearReason() {
      this.name = ''
      this.setReason(null)
    },
    selectReason() {
      this.picking = !this.picking
    },
    // 点击案由选择左侧 回退一层
    onNavClick(index) {
      this.mainActiveIndex = index
      const p = this.parentReason.parent
      if (p) {
        this.parentReason = p
        if (this.isEnd) {
          const gp = this.parentReason.parent
          if (gp) {
            this.parentReason = gp
            this.name = gp.text
            this.getSubReason()
          } else {
            this.$notify('没有上一级案由了!')
          }
        } else {
          this.name = p.text
          this.getSubReason()
        }
      } else {
        this.$notify('没有上一级案由了!')
      }
    },
    // 点击案由名称
    onItemClick(data) {
      // console.log(data, '点击案由名称')
      this.parentReason = data
      this.activeId = data.id
      this.name = data.text
      this.setReason(data)
      this.hideTreeSelect()
    },
    // 点击下一级案由
    onItemArrowClick(data) {
      // console.log(data, '下潜一层')
      this.parentReason = data
      this.activeId = data.id
      this.name = data.text
      this.setReason(data)
      this.getSubReason()
    },
    getSubReason() {
      const { id: pid, text: ptext } = this.parentReason
      // 从接口获取子数组，第一个数组传-1
      this.$axios
        .get(`${caseBase}/lts/commonCaseReason/subsetCaseReasonList?parentId=${pid}`)
        .then((res) => {
          this.searching = false
          if (res.data.code === '200') {
            const dataList = res.data.data
            if (dataList && dataList.length) {
              const children = []
              dataList.forEach((element) => {
                const { name: text, caseReasonId: id, caseReasonType } = element
                const parent = {
                  text: ptext,
                  id: pid,
                  caseReasonType: caseReasonType,
                  parent: this.parentReason.parent,
                }
                const i = {
                  text,
                  id,
                  parent,
                  caseReasonType: caseReasonType,
                }
                children.push(i)
              })
              const r = {
                text: this.parentReason.text,
                id: pid,
                caseReasonType: this.parentReason.caseReasonType,
                children,
              }
              this.isEnd = false
              const rs = [r]
              this.reasons = rs
            } else {
              this.picking = false
              this.isEnd = true
            }
          }
        })
        .catch((err) => {
          this.searching = false
          console.log(err)
        })
    },
    hideTreeSelect() {
      this.picking = false
    },
    confirm(displayUpdate = false) {
      const payload = {
        displayUpdate,
        reason: this.selectedReasonObj,
      }
      this.$emit('confirmSelect', payload)
    },
  },
  created() {
    this.getSubReason()
    if (this.reasonText && this.reasonText !== '') {
      this.name = this.reasonText
      const reasonObj = this.recursionName(ReasonData, this.reasonText)
      if (reasonObj) {
        const r = {
          text: reasonObj.label,
          id: parseInt(reasonObj.value),
        }
        this.setReason(r)
      }
    }
    this._search = debounce(400, true, (name) => {
      this.search(name)
    })
  },
  beforeDestroy() {
    this.name = ''
  },
  watch: {
    name(nv, ov) {
      if (nv) {
        this.$emit('change', nv)
        this._search(nv)
      } else {
        this.setReason(null)
        this.reasons = []
        this.suggestionList = []
        this.parentReason = {
          text: '选择案由',
          id: '-1',
          parent: null,
        }
        this.getSubReason()
      }

      this.$emit('selectData', { text: this.name, id: this.activeId })
    },
    flag(nv, ov) {
      const len = this.reasonSuggestion.length
      if (len) {
        const reasons = this.reasonSuggestion.map((item) => {
          if (item.summary_name) {
            const obj1 = {
              name: item.summary_name,
              caseReasonId: item.summary_name,
            }
            return obj1
          } else {
            const obj2 = {
              name: item,
              caseReasonId: item,
            }
            return obj2
          }
        })
        this.suggestionList = reasons
        /*  this.suggestionList[0].name === '咨询代书' ||
            this.suggestionList[0].name === '法律顾问' ||
            this.suggestionList[0].name === '其他非诉' */
        if (len === 1) {
          this.select({
            name: this.reasonSuggestion[0].summary_name,
            caseReasonId: this.reasonSuggestion[0].id,
          })
          // this.select(this.suggestionList[0])
        } else {
          this.showSuggestion = true
        }
      }
    },
  },
}
</script>

<style lang="stylus">
.reason-item
  width 100%
  border-radius 4px
  & #reason-input
    caret-color #222
  .van-cell
    border-radius 4px
  .confirm-btn
    width 100%
    height 48px
    display flex
    align-items center
    text-align center
    color #ffffff
    justify-content center
    position fixed
    bottom 0
    left 0
    font-size 15px
    .full-btn
      width 100%
    button
      margin-right 10px
.title-with-switch
  display flex
  align-items center
  position relative
  & .select-reason
    display flex
    width 120px
    margin-right 6px
    .select-reason-icon
      margin-right 5px
      font-size 14px
    .text
      padding-right 8px
      font-size 14px
      border-right 0.026667rem solid #eee
  .name-input
    width calc(100% - 10px)
    border none
  .name-input-clear
    color #999
    font-size 16px
    line-height 18px
    position absolute
    right 1px
.search-suggestion, .reason-picker
  margin-top 2px
  position absolute
  width calc(100% - 20px)
  background #ffffff
  max-height 60vw
  overflow-y auto
  box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
  border-radius 0 0 4px 4px
  z-index 10
  .van-ellipsis.van-tree-select__item.van-tree-select__item--active
    i
      font-size 16px !important
  .van-tree-select__nitem--active::after, .van-tree-select__nitem:active::after
    background-color #1989fa
  .select__item
    display flex
    justify-content space-between
    width 100%
    height 40px
    line-height 40px
    border-bottom 1px solid #ccc
    span
      flex 1
      overflow hidden
      text-indent 5px
    i
      width 30px
      text-align center
      line-height 40px
  & .van-loading
    display flex
    align-items center
    justify-content center
  & .suggestion-list-item
    display flex
    justify-content space-between
    align-items center
    padding 8px 18px
    .name
      font-size 14px
      color #555
    .attr
      font-size 13px
      color #888
.collapse-item__wrapper
  transition height 0.3s ease-in-out
  overflow hidden
  will-change height
  overflow-y auto
  .collapse-item__content
    padding 0.4rem
    background-color #fff
.id-type-radio-group
  display flex
  padding 5px 0
  justify-content space-between
  align-items center
  .van-radio
    .van-radio__input
      font-size 12px
      height 12px
      line-height 14px
      padding-left 2px
      box-sizing border-box
  .van-radio .van-icon-checked
    color #2781FD
.reason-picker
  height 480px
  max-height 480px
  overflow-y auto
  .van-tree-select
    height 100% !important
    .van-tree-select__nav
      .van-ellipsis.van-tree-select__nitem--active
        font-size 14px
        font-weight normal
        color #333
    .van-tree-select__content
      .van-ellipsis.van-tree-select__item--active
        color #569af9
        font-size 14px !important
        .van-icon.van-icon-checked.van-tree-select__selected
          font-size 14px !important
    .van-ellipsis.van-tree-select__item
      font-size 14px
      font-weight normal
      color #444
      border-bottom 0.026667rem solid #eee
.search-suggestion
  z-index 11
.no-reason-tip
  display flex
  align-items center
  height 44px
  line-height 44px
  padding-left 10px
  background rgba(0, 0, 0, 0.6)
  font-size 14px
  color #fcfcfc
.name-input
  border none
</style>
